import { Form, InputNumber } from 'antd';
import { TestKeys } from 'features/pages/UserDetails/UserDetails';
import { type ReactNode } from 'react';

interface IEditableCell {
  editing: boolean;
  title: string;
  children: ReactNode;
  key: string;
  colSpan: number;
  record: any;
}

const EditableCell = ({
  editing,
  title,
  children,
  record,
  colSpan,
}: IEditableCell) => {
  const formItemName =
    record?.formName === TestKeys.traits
      ? [record?.formName, record?.name, record?.testType]
      : [record?.formName, record?.name];

  const shouldTraitInputBeDisabled =
    (record?.testType === 'psychological' && record?.psychological) ||
    (record?.testType === 'dna' && record?.dna);

  const isInputDisabled =
    record?.formName === TestKeys.traits
      ? shouldTraitInputBeDisabled
      : record?.score;

  return (
    <td colSpan={colSpan}>
      {editing && children !== null ? (
        <Form.Item
          name={formItemName}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {isInputDisabled && <InputNumber style={{ width: 50 }} />}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
