import { useMutation } from '@tanstack/react-query';

import { send2FAToken, type UserCredentials } from '../userAPI';

export const useSend2FATokenMutation = () => {
  const send2FATokenMutation = useMutation({
    mutationFn: (values: UserCredentials) => send2FAToken(values),
  });
  return {
    send2FATokenMutation,
  };
};
