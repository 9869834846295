import { USER_QUIZ_TOKEN } from 'shared/constants/localStorageKeys';

export const paths = {
  main: '/',
  login: '/login',
  '2fa': '/2fa',
  reset: '/reset',
  companies: '/companies',
  companyDetails: '/companies/:companyId',
  userDetails: '/companies/:companyId/users/:referenceNumber',
  companyDetailsEdit: '/companies/:companyId/edit',
  addCompany: '/addCompany',
  users: '/users',
  quiz: '/quiz',
  quizUser: `/quiz/:${USER_QUIZ_TOKEN}`,
  quizQuestions: '/questions',
  quizCompleted: '/completed',
};

export type Paths = typeof paths;
