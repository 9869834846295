import { Alert, Typography } from 'antd';
import { type ErrorMessage } from 'shared/hooks/useErrorMessage';
import styles from './ErrorAlert.module.scss';

export const ErrorAlert = ({
  errorMessage,
}: {
  errorMessage: ErrorMessage;
}) => {
  return (
    <Alert
      className={styles.alert}
      showIcon
      type="error"
      message={
        <>
          {errorMessage.title && (
            <Typography.Paragraph className={styles.paragraph} strong>
              {errorMessage.title}
            </Typography.Paragraph>
          )}
          {Array.isArray(errorMessage.message) ? (
            <ul className={styles.list}>
              {errorMessage.message.map((message) => (
                <li key={message}>
                  <Typography.Text>- {message}</Typography.Text>
                </li>
              ))}
            </ul>
          ) : (
            <Typography.Paragraph className={styles.paragraph}>
              {errorMessage.message}
            </Typography.Paragraph>
          )}
        </>
      }
    />
  );
};
