import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'shared/constants/queryKeys';
import { fetchCompany, fetchCompanyUsers } from './companyAPI';
import { useLocation, useParams } from 'react-router-dom';
import {
  updateUser,
  type UpdateUserRequirements,
} from 'features/pages/AllUsers/api/allUsersAPI';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { fetchCompanyUser } from 'features/pages/Quiz/api/quizApi';
import { getUserQuizToken } from 'features/pages/Auth/userAPI';
import { useQuizContext } from 'features/pages/Quiz/shared/context/QuizContext';

interface UserQuizTokenProps {
  userId: string;
  companyId: string;
}

export const useCompanyUsersQuery = () => {
  const { search } = useLocation();
  const { companyId } = useParams<{ companyId: string }>();

  const companyUsersQuery = useQuery({
    queryKey: QUERY_KEYS.COMPANY_USERS.list(search),
    queryFn: () =>
      companyId
        ? fetchCompanyUsers(companyId, search)
        : Promise.reject(new Error('Expected company ID')),
    enabled: !!companyId,
  });

  return { companyUsersQuery };
};

export const useCompanyUserQuery = () => {
  const {
    userData: { companyId, userId, userQuizToken },
  } = useQuizContext();

  const companyUserQuery = useQuery({
    queryKey: QUERY_KEYS.COMPANY_USERS.detail(userId),
    queryFn: () =>
      fetchCompanyUser({
        companyId,
        userId,
      }),
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!companyId && !!userId && !!userQuizToken,
    useErrorBoundary: true,
  });

  return { companyUserQuery };
};

export const useCompanyDetailsQuery = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const companyDetailsQuery = useQuery({
    queryKey: QUERY_KEYS.COMPANIES.detail(companyId as string),
    queryFn: () =>
      companyId
        ? fetchCompany(companyId)
        : Promise.reject(new Error('Expected company ID')),
    enabled: !!companyId,
  });

  return { companyDetailsQuery };
};

export const useUserQuizTokenQuery = ({
  companyId,
  userId,
}: UserQuizTokenProps) => {
  const userQuizTokenQuery = useQuery({
    queryKey: QUERY_KEYS.USER_QUIZ_TOKEN.detail(userId),
    queryFn: () => getUserQuizToken({ companyId, userId }),
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!companyId && !!userId,
  });

  return { userQuizTokenQuery };
};

export const useCompanyDetailsMutations = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const updateCompanyUserMutation = useMutation({
    mutationFn: ({ companyId, userId, field }: UpdateUserRequirements) =>
      updateUser({ companyId, field, userId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ALL_USERS.lists() });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.COMPANY_USERS.lists(),
      });

      message.success(t('UpdateCompany.requestSuccess'));
    },
    onError: () => message.error(t('UpdateCompany.requestError')),
  });

  return { updateCompanyUserMutation };
};
