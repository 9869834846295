import { Col } from 'antd';
import { useRef, type ReactNode } from 'react';
import styles from './SwipeProvider.module.scss';

interface Props {
  children: ReactNode;
  handleSwipe: () => void;
  enabled: boolean;
}
const MIN_SWIPE_DISTANCE = 75;

export const SwipeProvider = ({ children, handleSwipe, enabled }: Props) => {
  const touchStartRef = useRef<number | null>(null);
  const touchEndRef = useRef<number | null>(null);

  return (
    <Col
      xs={24}
      flex={1}
      className={styles.wrapper}
      onTouchStart={(e) => {
        touchEndRef.current = null;
        touchStartRef.current = e.targetTouches[0].clientX;
      }}
      onTouchMove={(e) => {
        touchEndRef.current = e.targetTouches[0].clientX;
      }}
      onTouchEnd={() => {
        if (!touchStartRef.current || !touchEndRef.current) return;
        const distance = touchStartRef.current - touchEndRef.current;
        const isLeftSwipe = distance > MIN_SWIPE_DISTANCE;
        if (isLeftSwipe && enabled) {
          handleSwipe();
        }
      }}
    >
      {children}
    </Col>
  );
};
