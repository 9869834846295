import { Alert, Table as AntTable, Button, Col, Row, Typography } from 'antd';
import { type SearchParamsObject } from 'shared/types';
import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
} from 'shared/constants/defaultPagination';
import styles from './Table.module.scss';
import { type ColumnsType, type TableProps } from 'antd/es/table';
import variables from '../_styles/variables.module.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface RecordBase {
  id: string;
}

const Table = <T extends RecordBase>({
  loading,
  dataSource,
  pagination,
  applySearchParamsValues,
  columns,
  selectable,
  header: Header,
}: {
  loading: boolean;
  dataSource?: T[];
  pagination: TableProps<T>['pagination'];
  applySearchParamsValues: (values: SearchParamsObject) => void;
  columns: ColumnsType<T>;
  selectable?: boolean;
  header?: ({
    rows,
    isMassAction,
    rowsCount,
  }: {
    rows: string[];
    isMassAction: boolean;
    rowsCount?: number;
  }) => JSX.Element;
}) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<string[]>([]);
  const [isMassAction, setIsMassAction] = useState<boolean>(false);
  const tablePagination = pagination || undefined;
  const totalRows = tablePagination?.total;
  const pageSize = tablePagination?.pageSize;
  const isWholePageSelected =
    typeof pageSize === 'number' && rows.length >= pageSize;

  useEffect(() => {
    if (!isWholePageSelected && isMassAction) {
      setIsMassAction(false);
    }
  }, [isWholePageSelected, isMassAction]);

  return (
    <div key="users-table" className={styles.table}>
      <Row gutter={[20, 20]} justify="center">
        {Header && (
          <Col span={24} className={styles.header}>
            <Header
              rows={rows}
              isMassAction={isMassAction}
              rowsCount={isMassAction ? totalRows : rows.length}
            />
          </Col>
        )}
        {isWholePageSelected && !isMassAction && (
          <Col flex="0 0 auto">
            <Alert
              type="info"
              message={
                <>
                  <Typography.Text>
                    {t('Table.currentPageSelection', {
                      pageSize,
                    })}
                  </Typography.Text>{' '}
                  <Button
                    className={styles.selectAllButton}
                    type="link"
                    onClick={() => {
                      setIsMassAction(true);
                    }}
                  >
                    {t('Table.selectAllRowsButton', {
                      totalRows,
                    })}
                  </Button>
                </>
              }
            />
          </Col>
        )}
        <Col span={24}>
          <AntTable
            rowSelection={
              selectable
                ? {
                    type: 'checkbox',
                    selectedRowKeys: rows,
                    onChange: (selectedRowKey) => {
                      setRows(selectedRowKey.map((key) => key.toString()));
                    },
                  }
                : undefined
            }
            columns={columns}
            scroll={{ x: '100%' }}
            rowKey={({ id }) => id}
            dataSource={dataSource}
            loading={loading}
            onChange={(newPagination, _filters, newSorting) => {
              const offsetValue =
                newPagination.current && newPagination.pageSize
                  ? (
                      (newPagination.current - 1) *
                      newPagination.pageSize
                    ).toString()
                  : DEFAULT_OFFSET.toString();

              const limitValue = (
                newPagination.pageSize || DEFAULT_LIMIT
              ).toString();
              if (
                !Array.isArray(newSorting) &&
                typeof newSorting.field === 'string' &&
                newSorting.order
              ) {
                applySearchParamsValues({
                  sorting: {
                    orderBy: {
                      value: newSorting.field,
                    },
                    orderDir: {
                      value: newSorting.order === 'ascend' ? 'asc' : 'desc',
                    },
                  },
                  paging: {
                    limit: {
                      value: +limitValue,
                    },
                    offset: {
                      value: +offsetValue,
                    },
                  },
                });
              } else {
                applySearchParamsValues({
                  sorting: {
                    orderDir: {
                      value: undefined,
                    },
                    orderBy: {
                      value: undefined,
                    },
                  },
                  paging: {
                    limit: {
                      value: +limitValue,
                    },
                    offset: {
                      value: +offsetValue,
                    },
                  },
                });
              }
              setRows([]);
            }}
            pagination={
              pagination && {
                showSizeChanger: true,
                pageSizeOptions: [15, 30, 50, 100],
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: pagination.total,
              }
            }
            sticky={{
              getContainer: () => {
                return document.querySelector('.main-content') as HTMLElement;
              },
              offsetHeader: -variables.pageTilePadding,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Table;
