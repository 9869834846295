import { type Question as QuestionType } from '../api/quizApi';
import { TextAnswers } from './Answers/TextAnswers/TextAnswers';
import { Question } from './Questions/Question/Question';
import { type QuestionFormKeys } from '../shared/types/types';
import { CounterQuestion } from './Questions/CounterQuestion/CounterQuestion';
// import { EmojiAnswers } from './components/EmojiAnswers';
import { SliderAnswers } from './Answers/SliderAnswers/SliderAnswers';
import { PerceptionQuestion } from './Questions/PerceptionQuestion/PerceptionQuestion';
import { PerceptionQuestionAnswers } from './Answers/PerceptionAnswers/PerceptionQuestionAnswers';
import { ImageAnswers } from './Answers/ImageAnswers/ImageAnswers';
import { CounterQuestionAnswers } from './Answers/CounterAnwers/CounterQuestionAnswers';

export const renderQuestion = (
  question: QuestionType,
  questionFormKey: QuestionFormKeys,
  isSubmitDisabled: boolean,
) => {
  const hasImageAnswers = question.answers.some(
    (question) => !!question.imageUrl,
  );

  switch (question.question.type) {
    case 'singleChoice':
      switch (true) {
        case question.answers.some((answer) =>
          // TODO rework this solution (BE not allow to identify this type of question)
          answer.text.includes('Most likely this'),
        ):
          return (
            <PerceptionQuestion
              question={question}
              isSubmitDisabled={isSubmitDisabled}
            >
              <PerceptionQuestionAnswers
                answers={question.answers}
                questionFormKey={questionFormKey}
              />
            </PerceptionQuestion>
          );
        default:
          return (
            <Question question={question} isSubmitDisabled={isSubmitDisabled}>
              {hasImageAnswers ? (
                <ImageAnswers
                  type="single"
                  questionFormKey={questionFormKey}
                  answers={question.answers}
                />
              ) : (
                <TextAnswers
                  type="single"
                  questionFormKey={questionFormKey}
                  answers={question.answers}
                />
              )}
            </Question>
            //  <EmojiAnswers answers={question.answers} />
          );
      }
    case 'mutipleChoice':
      switch (true) {
        case question.answers.some((answer) =>
          // TODO rework this solution (BE not allow to identify this type of question)
          answer.text.includes("I've seen this"),
        ):
          return (
            <CounterQuestion
              question={question}
              isSubmitDisabled={isSubmitDisabled}
            >
              <CounterQuestionAnswers
                answers={question.answers}
                questionFormKey={questionFormKey}
              />
            </CounterQuestion>
            // <PerceptionQuestion
            //   question={question}
            //   isSubmitDisabled={isSubmitDisabled}
            // >
            //   <PerceptionQuestionAnswers
            //     answers={question.answers}
            //     questionFormKey={questionFormKey}
            //   />
            // </PerceptionQuestion>
          );

        default:
          return (
            <Question question={question} isSubmitDisabled={isSubmitDisabled}>
              {hasImageAnswers ? (
                <ImageAnswers
                  type="multiple"
                  questionFormKey={questionFormKey}
                  answers={question.answers}
                />
              ) : (
                <TextAnswers
                  type="multiple"
                  questionFormKey={questionFormKey}
                  answers={question.answers}
                />
              )}
            </Question>
          );
      }
    // FIX
    case 'randomOrder':
      return <></>;
    case 'slider':
      return (
        <Question question={question} isSubmitDisabled={isSubmitDisabled}>
          {/* <EmojiAnswers
            answers={question.answers}
            questionFormKey={questionFormKey}
          /> */}
          <SliderAnswers
            answers={question.answers}
            questionFormKey={questionFormKey}
          />
        </Question>
      );
  }
};
