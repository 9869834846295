import { type Question } from 'features/pages/Quiz/api/quizApi';
import { useEffect, type ReactNode, useState } from 'react';
import { COUNTER_QUESTION } from 'features/pages/Quiz/shared/constants/localStorageKeys';
import { QuestionWrapper } from '../../components/Questions/QuestionWrapper/QuestionWrapper';
import { Col } from 'antd';
import { TextHighlighter } from '../../components/TextHighlighter/TextHighlighter';
import { QuestionImage } from '../../components/Images/QuestionImage/QuestionImage';

interface Props {
  question: Question;
  children: ReactNode;
  isSubmitDisabled: boolean;
}

const SECONDS = 10;

export const CounterQuestion = ({
  children,
  question,
  isSubmitDisabled,
}: Props) => {
  const [timer, setTimer] = useState<number>(SECONDS);

  const isCounterQuestionActive = sessionStorage.getItem(COUNTER_QUESTION);
  const isCounterQuestionActiveParsed = isCounterQuestionActive
    ? JSON.parse(isCounterQuestionActive)
    : null;
  const displayQuestion = timer > 0 && !isCounterQuestionActiveParsed;

  useEffect(() => {
    const timeoutId = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1 * 1000);
    return () => {
      clearInterval(timeoutId);
    };
  }, []);

  return (
    <QuestionWrapper
      isSubmitDisabled={isSubmitDisabled}
      renderQuestion={() => (
        <>
          <Col span={24}>
            <TextHighlighter>{question.question.text}</TextHighlighter>
          </Col>
          {question.question.imageUrl && (
            <Col span={24}>
              <QuestionImage
                src={question.question.imageUrl}
                alt={question.question.text}
              />
            </Col>
          )}
        </>
      )}
      counterQuestion={{
        timer,
        displayQuestion,
      }}
    >
      {children}
    </QuestionWrapper>
  );
};
