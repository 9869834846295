import { Button, Tooltip } from 'antd';
import getDescription, {
  type DescriptionKey,
} from 'features/pages/UserDetails/Results/helpers/getDescription';
import { type PreparedResult } from 'features/pages/UserDetails/Results/results';

export const getTraitsColumns = (isEditing: boolean, formName: string) => [
  {
    title: 'No',
    key: 'index',
    dataIndex: 'index',
    width: 60,
  },
  {
    title: 'Trait',
    dataIndex: 'name',
    ellipsis: true,
    key: 'name',
    render: (name: DescriptionKey) => (
      <Tooltip
        placement="topLeft"
        title={getDescription(name)}
        color="#FD7446"
        key={name}
      >
        <Button style={{ width: '100%', textAlign: 'left' }} type="text">
          <span
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: '100%',
            }}
          >
            {name}
          </span>
        </Button>
      </Tooltip>
    ),
  },
  {
    title: 'Gen',
    width: 70,
    dataIndex: 'dna',
    key: 'dna',
    editable: true,
    onCell: (record: PreparedResult) => ({
      record: { ...record, testType: 'dna', formName },
      inputType: 'number',
      dataIndex: 'dna',
      key: `${record.name}.dna`,
      title: 'Gen',
      editing: isEditing,
    }),
  },
  {
    title: 'PSY',
    width: 70,
    dataIndex: 'psychological',
    key: 'psychological',
    editable: true,
    onCell: (record: PreparedResult) => {
      return {
        record: { ...record, testType: 'psychological', formName },
        inputType: 'number',
        dataIndex: 'psychological',
        key: `${record.name}.psychological`,
        title: 'Psy',
        editing: isEditing,
      };
    },
  },
];
