import { useState } from 'react';
import { Button, Form, Row, Typography, theme } from 'antd';
import styles from '../Answers.module.scss';
import { type AnswerType } from 'features/pages/Quiz/api/quizApi';
import {
  type QuestionForm,
  type QuestionFormKeys,
} from 'features/pages/Quiz/shared/types/types';
import { questionFormInitialValues } from '../../QuizQuestions';
import { AnswersWrapper } from '../../components/Answers/AnswersWrapper/AnswersWrapper';
import { ImageAnswerWrapper } from '../../components/Answers/ImageAnswerWrapper/ImageAnswerWrapper';
import { CoveredImage } from '../../components/Images/CoveredImage/CoveredImage';

interface Props {
  answers: AnswerType[];
  questionFormKey: QuestionFormKeys;
}

export const PerceptionQuestionAnswers = ({
  answers,
  questionFormKey,
}: Props) => {
  const form = Form.useFormInstance();
  const watchedAnswers =
    Form.useWatch<QuestionForm['answers']>(questionFormKey) ||
    questionFormInitialValues.answers;
  const { token } = theme.useToken();
  const [flipped, setFlipped] = useState<string | null>(null);

  return (
    <AnswersWrapper
      renderHelperText={() => (
        <Typography.Text strong>Most likely:</Typography.Text>
      )}
    >
      <Row gutter={[16, 16]} justify="center">
        {answers.map((answer) => {
          const isAnswerFlipped = flipped === answer.id;
          const selectedStateColor = watchedAnswers.includes(answer.id)
            ? token.colorPrimary
            : token.colorBorder;

          return (
            <ImageAnswerWrapper
              key={answer.id}
              answerId={answer.id}
              color={selectedStateColor}
              themeConfig={{
                token: {
                  colorFill: '#91EE91',
                },
              }}
            >
              <CoveredImage
                flipped={isAnswerFlipped}
                imageUrl={answer.imageUrl}
                imageText={answer.text}
                render={(data) => {
                  return (
                    <Form.Item
                      className={styles.formItem}
                      name={questionFormKey}
                    >
                      <Button
                        onMouseEnter={() => {
                          setFlipped((prev) =>
                            prev === answer.id ? null : answer.id,
                          );
                        }}
                        onMouseLeave={() => {
                          setFlipped(null);
                        }}
                        onClick={() => {
                          if (
                            Array.isArray(watchedAnswers) &&
                            isAnswerFlipped
                          ) {
                            form.setFieldValue(
                              questionFormKey,
                              watchedAnswers.includes(answer.id)
                                ? watchedAnswers.filter(
                                    (answerId: string) =>
                                      answerId !== answer.id,
                                  )
                                : [answer.id],
                            );
                          }
                        }}
                        className={styles.answerButton}
                      >
                        {data}
                      </Button>
                    </Form.Item>
                  );
                }}
              />
            </ImageAnswerWrapper>
          );
        })}
      </Row>
    </AnswersWrapper>
  );
};
