import { type FilterFieldConfig } from 'shared/types';
import { FilterInput } from '../FilterInput/FilterInput';
import { FilterSelect } from '../FilterSelect/FilterSelect';

export const renderFilterField = <T extends string>(
  field: FilterFieldConfig<T>,
) => {
  switch (field.component.type) {
    case 'input':
      return (
        <FilterInput
          label={field.component.label}
          name={field.key}
          placeholder={field.component.placeholder}
          type={field.component.inputType}
        />
      );
    case 'select':
      return (
        <FilterSelect
          name={field.key}
          label={field.component.label}
          options={field.component.options}
          placeholder={field.component.placeholder}
        />
      );
  }
};
