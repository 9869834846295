import { type FormDataObject } from '../ResultsForm';

type TraitsFormObject = Record<
  string,
  { dna: string | undefined; psychological: string | undefined }
>;

export const findFormDataObjectChanges = (
  oldObject: FormDataObject | undefined = {},
  newObject: FormDataObject | undefined = {},
) => {
  const modifiedValues = Object.entries(newObject).filter(([newKey, newVal]) =>
    Object.entries(oldObject).find(([oldKey, oldVal]) =>
      newKey === oldKey && newVal !== oldVal && newVal ? 1 : undefined,
    ),
  );

  return modifiedValues.map(([key, value]) => ({
    name: key,
    score: Number(value),
  }));
};

export const traitsFormDataObjectChanges = (
  oldObject: TraitsFormObject | undefined = {},
  newObject: TraitsFormObject | undefined = {},
) => {
  const results: Record<string, Array<{ name: string; score: number }>> = {
    psyTraits: [],
    dnaTraits: [],
  };

  Object.entries(newObject).forEach(([newKey, newVal]) => {
    Object.entries(oldObject).forEach(([oldKey, oldVal]) => {
      if (newKey === oldKey) {
        if (newVal.dna && newVal.dna !== oldVal.dna) {
          results.dnaTraits.push({ name: newKey, score: Number(newVal.dna) });
        }
        if (
          newVal.psychological &&
          newVal.psychological !== oldVal.psychological
        ) {
          results.psyTraits.push({
            name: newKey,
            score: Number(newVal.psychological),
          });
        }
      }
    });
  });

  return results;
};
