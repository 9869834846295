import { Button, Tooltip } from 'antd';
import getDescription, {
  type DescriptionKey,
} from 'features/pages/UserDetails/Results/helpers/getDescription';
import { type ScoreResult } from 'features/pages/UserDetails/Results/results';
import { type TestKeys } from 'features/pages/UserDetails/UserDetails';
import { isSchoolName } from 'helpers/typeGuards';

export const getTabColumns = (
  isEditing: boolean,
  formName: Omit<TestKeys, 'traits'>,
) => [
  {
    title: 'No',
    key: 'index',
    dataIndex: 'index',
    width: 60,
  },
  {
    title: 'Trait',
    ellipsis: true,
    key: 'name',
    render: (item: ScoreResult) => (
      <Tooltip
        placement="topLeft"
        title={getDescription(item.name as DescriptionKey)}
        color="#FD7446"
        key={item.name}
      >
        <div
          style={{
            width: '100%',
            textAlign: 'left',
            overflow: 'hidden',
            flexDirection: 'column',
          }}
        >
          <Button type="text">
            <span
              style={{
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
            >
              {item.name}
            </span>
          </Button>
          {isSchoolName(item) && (
            <p
              style={{
                textOverflow: 'ellipsis',
                maxWidth: '100%',
                marginTop: 0,
                marginBottom: 0,
                paddingLeft: 15,
              }}
            >
              {item.label}
            </p>
          )}
        </div>
      </Tooltip>
    ),
  },
  {
    title: 'Score',
    width: 80,
    dataIndex: 'score',
    key: 'score',
    editable: true,
    onCell: (record: ScoreResult) => {
      return {
        record: { ...record, formName },
        inputType: 'number',
        dataIndex: 'score',
        key: `${record.name}`,
        title: 'Score',
        editing: record?.score && isEditing,
      };
    },
  },
];
