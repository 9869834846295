import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import labelsPL from './pl/labels.json';
import labelsEN from './en/labels.json';

export const defaultNS = 'translations';
export const resources = {
  en: {
    translations: labelsEN,
  },
  pl: {
    translations: labelsPL,
  },
} as const;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: 'en',
    ns: [defaultNS],
    resources,
    defaultNS,
  });
