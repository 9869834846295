import { Breadcrumb, Descriptions } from 'antd';
import ResultsForm from 'features/pages/UserDetails/Results/ResultsForm';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './UserDetails.module.scss';
import { useUserDetailsQuery } from './userDetailsQuery';

export enum TestKeys {
  traits = 'traits',
  professionsWithInterest = 'professionsWithInterest',
  professionsWithoutInterest = 'professionsWithoutInterest',
  managementStyles = 'managementStyles',
  schoolSubjectsWithInterest = 'schoolSubjectsWithInterest',
  schoolSubjectsWithoutInterest = 'schoolSubjectsWithoutInterest',
}
export enum TestTypes {
  dna = 'dna',
  psychological = 'psychological',
}

const UserDetails = () => {
  const { t } = useTranslation();
  const { data } = useUserDetailsQuery();

  if (!data?.data) return null;

  return (
    <div className={styles.container}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/">{t('Navbar.Home')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/users">{t('Navbar.Users')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/">{data.data.id}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.descriptions}>
        <Descriptions size="small" title={t('UserDetails.title')} column={2}>
          <Descriptions.Item
            labelStyle={{ minWidth: '110px' }}
            label={t('UserDetails.id')}
          >
            {data.data.id}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ minWidth: '110px' }}
            label={t('UserDetails.companyId')}
          >
            <Link to={`/companies/${data.data.companyId}`}>
              {data.data.companyId}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ minWidth: '110px' }}
            label={t('UserDetails.barcode')}
          >
            {data.data.barcode}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ minWidth: '110px' }}
            label={t('UserDetails.companyName')}
          >
            {data.data.companyName}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ minWidth: '110px' }}
            label={t('UserDetails.ageGroup')}
          >
            {data.data.ageGroup}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ minWidth: '110px' }}
            label={t('UserDetails.fullReport')}
          >
            ???
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div>
        <ResultsForm />
      </div>
    </div>
  );
};

export default UserDetails;
