import { Col, ConfigProvider, Form, Row, Typography } from 'antd';
import ReactSlider from 'react-slider';
import { useState } from 'react';
import { type AnswerType } from 'features/pages/Quiz/api/quizApi';
import {
  type QuestionForm,
  type QuestionFormKeys,
} from 'features/pages/Quiz/shared/types/types';
import { questionFormInitialValues } from '../../QuizQuestions';
import { AnswersWrapper } from '../../components/Answers/AnswersWrapper/AnswersWrapper';
import { AnswerImage } from '../../components/Images/AnswerImage/AnswerImage';
import { TextHighlighter } from '../../components/TextHighlighter/TextHighlighter';
import './SliderAnswers.css';

interface Props {
  answers: AnswerType[];
  questionFormKey: QuestionFormKeys;
}

function calculateBorderColor(
  currentMarkIndex: number,
  totalMarks: number,
  imageIndex: number,
) {
  const middleColor = 0.1;
  const extremeColor = 0.8;

  const currentMark = currentMarkIndex + 1;

  // Calculate the index of the middle mark
  const middleMark = Math.ceil(totalMarks / 2);

  // Calculate the number of steps from the middle mark for the current mark
  const stepsFromMiddle = Math.abs(middleMark - currentMark);

  // Calculate the color evenly between the extreme (dark) and middle (light) colors based on the number of marks
  const color =
    (stepsFromMiddle * (extremeColor - middleColor)) / (middleMark - 1) +
    middleColor;

  const rgba = `rgba(0,0,0,${color.toFixed(2)})`;

  // Calculate which image is iterated
  const isLeftImage = totalMarks / 2 - imageIndex > 0;
  const isRightImage = totalMarks / 2 - imageIndex < 0;

  // Calculate on which side from middle current mark is
  const isCurrentMarkOnLeftSide = currentMark < totalMarks / 2;
  const isCurrentMarkOnRightSide = currentMark > totalMarks / 2;

  // Return calculated color if iterated image and current mark side match, otherwise return basic color
  if (
    (isLeftImage && isCurrentMarkOnLeftSide) ||
    (isRightImage && isCurrentMarkOnRightSide)
  ) {
    return rgba;
  } else {
    return `rgba(0,0,0,${middleColor})`;
  }
}

export const SliderAnswers = ({ answers, questionFormKey }: Props) => {
  const form = Form.useFormInstance();
  const watchedAnswers =
    Form.useWatch<QuestionForm['answers']>(questionFormKey) ||
    questionFormInitialValues.answers;

  const answersWithImage = answers.filter((answer) => answer.imageUrl);
  const [currentMarkIndex, setCurrentMarkIndex] = useState<number>(2);

  return (
    <AnswersWrapper
      renderAdditionalData={() => (
        <Row gutter={[16, 16]} align={'top'}>
          {answers.map((answer, index) =>
            !answer.imageUrl ? null : (
              <Col span={12} key={answer.imageUrl}>
                <AnswerImage
                  src={answer.imageUrl}
                  answerText={answer.text}
                  render={(data) => (
                    <div
                      style={{
                        border: `5px solid ${calculateBorderColor(
                          currentMarkIndex,
                          answers.length,
                          index + 1,
                        )}`,
                      }}
                    >
                      {data}
                    </div>
                  )}
                />
              </Col>
            ),
          )}
        </Row>
      )}
      renderHelperText={() => <Typography.Text strong>Choose:</Typography.Text>}
    >
      <Row justify={'center'}>
        {!answersWithImage.length && (
          <Col span={3}>
            <div style={{ fontSize: '2em' }}>👍</div>
          </Col>
        )}
        <Col span={!answersWithImage.length ? 18 : 24}>
          <div
            onTouchStart={(e) => {
              e.stopPropagation();
            }}
          >
            <ConfigProvider
              theme={{
                token: {
                  controlHeight: 50,
                },
              }}
            >
              <Form.Item
                // FIX
                style={{ flex: 1 }}
                name={questionFormKey}
                getValueFromEvent={(value) => [answers[value].id]}
                getValueProps={(value) => value}
              >
                <ReactSlider
                  className={'horizontalSlider'}
                  thumbClassName={'thumb'}
                  trackClassName={'track'}
                  markClassName={'mark'}
                  defaultValue={2}
                  min={0}
                  max={answers.length - 1}
                  marks={true}
                  onSliderClick={(value) => {
                    if (!watchedAnswers.length) {
                      form.setFieldValue(questionFormKey, answers[value].id);
                    }
                  }}
                  onChange={(value) => {
                    setCurrentMarkIndex(value);
                  }}
                />
              </Form.Item>
            </ConfigProvider>
          </div>
        </Col>
        {!answersWithImage.length && (
          <Col span={3}>
            <div style={{ fontSize: '2em' }}>👎</div>
          </Col>
        )}
        <Col>
          {answers.map((answer) =>
            !watchedAnswers.length ||
            answer.id !== watchedAnswers[0] ||
            !answer.text ? null : (
              <TextHighlighter key={answer.id}>
                <Typography.Text>{answer.text}</Typography.Text>
              </TextHighlighter>
            ),
          )}
        </Col>
      </Row>
    </AnswersWrapper>
  );
};
