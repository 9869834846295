import { Descriptions, Divider } from 'antd';
import { type UserItem } from 'features/pages/AllUsers/api/allUsersAPI';
import { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';

interface IComponentToPrint {
  item: UserItem;
  Traits: JSX.Element;
  ProfessionsWithInterest: JSX.Element;
  ProfessionsWithoutInterest: JSX.Element;
  ManagementStyle: JSX.Element;
  SchoolSubjectWithInterest: JSX.Element;
  SchoolSubjectWithoutInterest: JSX.Element;
}

const ComponentToPrint = forwardRef<HTMLDivElement, IComponentToPrint>(
  (
    {
      item,
      Traits,
      ProfessionsWithInterest,
      ProfessionsWithoutInterest,
      ManagementStyle,
      SchoolSubjectWithInterest,
      SchoolSubjectWithoutInterest,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    return (
      <div ref={ref} style={{ width: '100%', padding: '10px 0 10px 10px' }}>
        <Descriptions size="small" title={t('UserDetails.title')} column={3}>
          <Descriptions.Item label={t('UserDetails.companyName')}>
            {item.companyName}
          </Descriptions.Item>
          <Descriptions.Item label={t('UserDetails.companyId')}>
            {item.companyId}
          </Descriptions.Item>
          <Descriptions.Item label={t('UserDetails.userId')}>
            {item.id}
          </Descriptions.Item>
        </Descriptions>
        <Divider>{t('UserDetails.traits')}</Divider>
        {Traits}
        <Divider>{t('UserDetails.professionsWithInterest')}</Divider>
        {ProfessionsWithInterest}
        <Divider>{t('UserDetails.professionsWithoutInterest')}</Divider>
        {ProfessionsWithoutInterest}
        <Divider>{t('UserDetails.managementStyles')}</Divider>
        {ManagementStyle}
        <Divider>{t('UserDetails.schoolSubjectsWithInterest')}</Divider>
        {SchoolSubjectWithInterest}
        <Divider>{t('UserDetails.schoolSubjectsWithoutInterest')}</Divider>
        {SchoolSubjectWithoutInterest}
      </div>
    );
  },
);

export default memo(ComponentToPrint);
