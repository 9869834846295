import { Badge } from 'antd';
import { type PsyTestStatus as PsyTestStatusType } from 'features/pages/AllUsers/api/allUsersAPI';
import { useTranslation } from 'react-i18next';

interface Props {
  psyTestStatus: PsyTestStatusType;
}

const PsyTestStatus = ({ psyTestStatus }: Props) => {
  const { t } = useTranslation();

  switch (psyTestStatus) {
    case 'PsychologicalTestStarted': {
      return (
        <Badge color="orange" text={t('UserTable.TestStatus.psyTestStarted')} />
      );
    }
    case 'PsychologicalTestCompleted': {
      return (
        <Badge
          color="green"
          text={t('UserTable.TestStatus.psyTestCompleted')}
        />
      );
    }
    default:
      return <Badge color="red" text={t('UserTable.TestStatus.noPSY')} />;
  }
};

export default PsyTestStatus;
