import { Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'helpers/routing/paths';
import { type MenuItem } from 'shared/types';
import { type CompanyItem } from 'features/pages/Companies/api/companiesAPI';
import { useCompaniesMutations } from 'features/pages/Companies/api/useCompaniesQuery';

interface Props {
  record: CompanyItem;
}

const ActionDropdown = ({ record }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deleteCompanyMutation } = useCompaniesMutations();

  const items: MenuItem[] = [
    {
      key: '0',
      onClick: () => {
        navigate(`${paths.companies}/${record.id}`);
      },
      label: t('Companies.seeProfile'),
    },
    {
      key: '1',
      onClick: () => {
        navigate(`${paths.companies}/${record.id}/edit`);
      },
      label: t('Companies.edit'),
    },
    {
      key: '2',
      onClick: () => {
        deleteCompanyMutation.mutate(record.id);
      },
      label: t('Companies.delete'),
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <MoreOutlined />
    </Dropdown>
  );
};

export default ActionDropdown;
