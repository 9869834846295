import { useSpring, a } from '@react-spring/web';
import { Typography, theme } from 'antd';
import styles from './CoveredImage.module.scss';
import { isMobile } from 'react-device-detect';

interface Props {
  flipped: boolean;
  imageUrl?: string;
  imageText?: string;
  seenImage?: boolean;
  isCounterQuestion?: boolean;
  render: (data: JSX.Element) => JSX.Element;
}

export const CoveredImage = ({
  flipped,
  imageUrl,
  imageText,
  seenImage,
  isCounterQuestion,
  render,
}: Props) => {
  const { token } = theme.useToken();
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const mobileHelperText = (
    <>
      <Typography.Paragraph strong className={styles.text}>
        Tap to see
      </Typography.Paragraph>
      <Typography.Paragraph strong className={styles.text}>
        Tap again
        <br />
        to mark
      </Typography.Paragraph>
    </>
  );
  const desktopHelperText = (
    <>
      <Typography.Paragraph strong className={styles.text}>
        Hover to see
      </Typography.Paragraph>
      <Typography.Paragraph strong className={styles.text}>
        Click to mark
      </Typography.Paragraph>
    </>
  );

  const data = (
    <div className={styles.imageContainer}>
      <a.div
        className={styles.coveredImage}
        style={{
          opacity: opacity.to((o) => 1 - o),
          transform,
          // TODO change this solution
          backgroundColor: token.colorFill,
        }}
      >
        {isMobile ? mobileHelperText : desktopHelperText}
      </a.div>
      <a.img
        src={imageUrl}
        alt={imageText}
        className={styles.image}
        style={{
          opacity,
          transform,
          rotateY: '180deg',
        }}
      />
    </div>
  );

  return (
    <>
      {isCounterQuestion && (
        <Typography.Text
          className={styles.helperText}
          style={{
            visibility: seenImage ? 'visible' : 'hidden',
          }}
        >
          {imageText}
        </Typography.Text>
      )}
      {render(data)}
    </>
  );
};
