import { type ReactNode } from 'react';
import styles from './TextHighlighter.module.scss';
import { Typography } from 'antd';

export const TextHighlighter = ({ children }: { children: ReactNode }) => {
  return (
    <Typography.Text strong className={styles.textHighlighter}>
      {children}
    </Typography.Text>
  );
};
