import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'shared/constants/queryKeys';
import { deleteCompany, fetchCompanies } from './companiesAPI';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

export const useCompaniesQuery = () => {
  const { search } = useLocation();

  const companiesQuery = useQuery({
    queryKey: QUERY_KEYS.COMPANIES.list(search),
    queryFn: () => fetchCompanies(search),
  });

  return { companiesQuery };
};

export const useCompaniesMutations = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const deleteCompanyMutation = useMutation({
    mutationFn: (id: string) => deleteCompany(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMPANIES.lists() });

      message.success(t('DeleteCompany.requestSuccess'));
    },
    onError: () => message.error(t('DeleteCompany.requestError')),
  });

  return { deleteCompanyMutation };
};
