import { Outlet } from 'react-router-dom';
import { Button, Layout as AntLayout, Select, ConfigProvider } from 'antd';
import Navbar from 'features/navbar/Navbar';
import { useTranslation } from 'react-i18next';
import { paths } from 'helpers/routing/paths';
import styles from './Layout.module.scss';
import { ModalContextProvider } from 'features/pages/Quiz/shared/context/ModalContext';
import { useUserContext } from 'shared/context/UserContext';

export const Layout = () => {
  const { i18n, t } = useTranslation();
  const { logOut } = useUserContext();
  const handleChange = (value: string) => i18n.changeLanguage(value);

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            colorBgHeader: '#fd7446',
          },
        },
      }}
    >
      <ModalContextProvider>
        <AntLayout className={styles.container}>
          <AntLayout.Header className={styles.header}>
            <div>{t('common.appName')}</div>
            <div className={styles.actions}>
              <div className={styles.languageSelect}>
                <Select defaultValue={i18n.language} onChange={handleChange}>
                  <Select.Option value="pl">{t('common.pl')}</Select.Option>
                  <Select.Option value="en">{t('common.en')}</Select.Option>
                </Select>
              </div>
              <Button
                onClick={() => {
                  logOut();
                }}
              >
                {t('common.logOut')}
              </Button>
            </div>
          </AntLayout.Header>
          <AntLayout className={styles.layout}>
            <Navbar paths={paths} />
            <div className={`${styles.mainContent} main-content`}>
              <Outlet />
            </div>
          </AntLayout>
        </AntLayout>
      </ModalContextProvider>
    </ConfigProvider>
  );
};
