import { type ResultStatusType } from 'antd/es/result';
import axios, { type AxiosError } from 'axios';
import { useTranslation, type TFunction } from 'react-i18next';

export interface ErrorMessage {
  title?: string;
  message: string | string[];
  requestMessage?: string;
  resultComponentStatusCode?: ResultStatusType;
}

export const errorMessageKeys = {
  userQuizToken: 'userQuizToken',
  missing2FAToken: 'missing2FAToken',
} as const;

type ErrorMessageKeys =
  (typeof errorMessageKeys)[keyof typeof errorMessageKeys];

type StatusErrorMessageMap = Record<number, ErrorMessage>;
type ErrorMessageMap = Record<ErrorMessageKeys, ErrorMessage>;

function hasErrorMessageInResponseData(
  errorResponseData: unknown,
): errorResponseData is { message: string } {
  return (errorResponseData as { message: string }).message !== undefined;
}

function isValidErrorMessageKey(
  key: string,
  t: TFunction,
): key is ErrorMessageKeys {
  const errorMessageMap = getErrorMessageMap(t);
  return key in errorMessageMap;
}

const getErrorMessageMap = (t: TFunction): ErrorMessageMap => ({
  [errorMessageKeys.userQuizToken]: {
    message: t('Error.InvalidLink.message'),
    title: t('Error.InvalidLink.title'),
    resultComponentStatusCode: 404,
  },
  [errorMessageKeys.missing2FAToken]: {
    message: t('Error.Missing2FAToken.message'),
    title: t('Error.Missing2FAToken.title'),
  },
});

const getDefaultStatusErrorsMessageMap = (
  t: TFunction,
): StatusErrorMessageMap => ({
  500: {
    message: t('Error.InternalServerError.message'),
    title: '500 Internal Server Error',
    resultComponentStatusCode: 500,
  },
  503: {
    message: t('Error.ServiceUnavailable.message'),
    title: '503 Service Unavailable',
    resultComponentStatusCode: 500,
  },
  400: {
    message: t('Error.BadRequest.message'),
    title: '400 Bad Request',
    resultComponentStatusCode: 404,
  },
  401: {
    message: t('Error.Unauthorized.message'),
    title: '401 Unauthorized',
    resultComponentStatusCode: 403,
  },
  403: {
    message: t('Error.Forbidden.message'),
    title: '403 Forbidden',
    resultComponentStatusCode: 403,
  },
  404: {
    message: t('Error.NotFound.message'),
    title: '404 Not Found',
    resultComponentStatusCode: 404,
  },
});

const getUnknownError = (t: TFunction): ErrorMessage => ({
  message: t('Error.Unknown.message'),
  title: t('Error.Unknown.title'),
  resultComponentStatusCode: 500,
});

const getErrorMessage = (errorKey: string, t: TFunction): ErrorMessage => {
  if (isValidErrorMessageKey(errorKey, t)) {
    const errorMessageMap = getErrorMessageMap(t);
    return errorMessageMap[errorKey];
  }
  return getUnknownError(t);
};

const getStatusErrorMessage = (
  errorStatus: number,
  t: TFunction,
  errorResponse?: AxiosError['response'],
  customAxiosErrorMessageMap?: StatusErrorMessageMap,
): ErrorMessage => {
  const errorsMap = {
    ...getDefaultStatusErrorsMessageMap(t),
    ...customAxiosErrorMessageMap,
  };

  let errorMessage: ErrorMessage;

  if (errorsMap[errorStatus]) {
    errorMessage = errorsMap[errorStatus];
  } else if (errorStatus >= 400 && errorStatus < 500) {
    errorMessage = {
      message: t('Error.Client.message'),
      title: t('Error.Client.title'),
      resultComponentStatusCode: 404,
    };
  } else if (errorStatus >= 500 && errorStatus < 600) {
    errorMessage = {
      message: t('Error.Server.message'),
      title: t('Error.Server.title'),
      resultComponentStatusCode: 500,
    };
  } else {
    errorMessage = getUnknownError(t);
  }

  if (errorResponse && hasErrorMessageInResponseData(errorResponse.data)) {
    errorMessage.requestMessage = errorResponse.data.message;
  }
  return errorMessage;
};

export const useErrorMessage = ({
  error,
  status,
  customStatusErrorMessageMap,
}: {
  error: unknown;
  status?: number;
  customStatusErrorMessageMap?: StatusErrorMessageMap;
}) => {
  const { t } = useTranslation();

  let errorMessage: ErrorMessage;

  if (axios.isAxiosError(error)) {
    if (error.response) {
      errorMessage = getStatusErrorMessage(
        error.response.status,
        t,
        error.response,
        customStatusErrorMessageMap,
      );
    } else if (error.request) {
      errorMessage = {
        message: t('Error.Network.message'),
        title: t('Error.Network.title'),
        resultComponentStatusCode: 500,
      };
    } else {
      errorMessage = getUnknownError(t);
    }
  } else if (error instanceof Error) {
    errorMessage = getErrorMessage(error.message, t);
  } else if (status) {
    errorMessage = getStatusErrorMessage(status, t);
  } else {
    errorMessage = getUnknownError(t);
  }

  return {
    errorMessage,
  };
};
