import { type TableProps } from 'antd';
import { type ResultInfo } from 'shared/types/apiResponse';

export const convertResponseToTablePagination = <T>(
  responsePagination?: ResultInfo['paging'],
): TableProps<T>['pagination'] => {
  if (!responsePagination) return false;

  const pageSize = responsePagination.limit;
  const total = responsePagination.totalCount;
  const current = responsePagination.offset / responsePagination.limit + 1;

  return {
    current,
    pageSize,
    total,
  };
};
