import { Button, Col, Grid, Row, Typography, theme } from 'antd';
import { QuizHeaderLayout } from 'features/common/layout/QuizHeaderLayout';
import { PsyTestStatuses } from 'features/pages/AllUsers/api/allUsersAPI';
import { paths } from 'helpers/routing/paths';
import { Navigate, useNavigate } from 'react-router-dom';
import { useCompanyUserQuery } from '../../CompanyDetails/api/useCompanyQuery';
import styles from '../Quiz.module.scss';
import backgroundImage from '../assets/mobile-background-image.svg';
import { PageSpinner } from '../components/PageSpinner/PageSpinner';
import { useUserTokenParam } from '../shared/hooks/useUserTokenParam';

const WHITE_PAPER_URL =
  'https://karmagenes.co/wp-content/uploads/2023/12/Karmagenes_White_Paper.pdf';

export const QuizStart = () => {
  const { token } = theme.useToken();
  const screens = Grid.useBreakpoint();
  const { companyUserQuery } = useCompanyUserQuery();
  const { userQuizToken } = useUserTokenParam();
  const navigate = useNavigate();
  const ageGroup = sessionStorage.getItem('ageGroup');

  const isLoading = companyUserQuery.isLoading;
  const isQuizCompleted = companyUserQuery.data?.data.psyTestCompletedAt;
  const isQuizStarted = companyUserQuery.data?.data.psyTestCurrentQuestion;

  return isQuizStarted && userQuizToken && !isLoading ? (
    <Navigate
      to={`${paths.quiz}/${userQuizToken}${paths.quizQuestions}`}
      replace
    />
  ) : isQuizCompleted && userQuizToken && !isLoading ? (
    <Navigate
      to={`${paths.quiz}/${userQuizToken}${paths.quizCompleted}`}
      replace
    />
  ) : (
    <QuizHeaderLayout displayHeader>
      <div className={`${styles.wrapper} ${styles.rounded}`}>
        {isLoading ? (
          <PageSpinner />
        ) : (
          <Row wrap={!screens.md} className={styles.content}>
            <Col>
              <Row gutter={[0, 20]}>
                <Col>
                  <Typography.Title level={1} className={styles.title}>
                    Get to know{' '}
                    <span
                      style={{ color: token.colorTextSecondary }}
                      className={styles.boldTitle}
                    >
                      Your child&apos;s personality
                    </span>
                  </Typography.Title>
                </Col>
                <Col>
                  {ageGroup === '1' ? (
                    <Typography.Paragraph>
                      This psychological assessment demo includes a pool of
                      2500+ questions based on the Big5/OCEAN model. This
                      psychological assessment takes approximately 10-15
                      minutes, and it is performed with an average of 80-100
                      dynamically selected questions, depending on the
                      respondent&apos;s behaviour and deduction of redundant
                      answers. The results are only indicative and used only for
                      this Demo. The data of this Demo is stored in AWS servers
                      for 12 months. The assessment can be paused and restarted
                      using the same IP address.
                      <ul>
                        Minimum browser requirements:
                        <li>Google Chrome 54 or later</li>
                        <li>Apple Safari on the iPad</li>
                        <li>Microsoft Internet Explorer 11 or later</li>
                        <li>Mozilla Firefox ESR 45 or version 48 or later</li>
                      </ul>
                      Minimum browser requirements: Apple Safari on the iPad
                      Microsoft Internet Explorer 11 or later Mozilla Firefox
                      ESR 45 or version 48 or later Multivariate and
                      multi-weighted analysis per trait, high internal
                      consistency (0.90-0.91) and 90%-97% test-retest
                      reliability over 2 years.
                    </Typography.Paragraph>
                  ) : (
                    <Typography.Paragraph>
                      This psychological image-based assessment demo includes a
                      10-15 minute child self-assessment (how the child
                      assesses) and a 10-15 minute parent/legal guardian (how
                      the parent/legal guardian assesses their child). The
                      results are only indicative and used only for this Demo.
                      The data of this Demo is stored in AWS servers for 12
                      months. The assessment can be paused and restarted at any
                      time using the same IP address.
                      <ul>
                        Minimum browser requirements:
                        <li>Google Chrome 54 or later</li>
                        <li>Apple Safari on the iPad</li>
                        <li>Microsoft Internet Explorer 11 or later</li>
                        <li>Mozilla Firefox ESR 45 or version 48 or later</li>
                      </ul>
                      Multivariate and multi-weighted analysis per trait, high
                      internal consistency (0.90-0.91) and 90%-97% test-retest
                      reliability over 2 years.
                    </Typography.Paragraph>
                  )}
                  <Typography.Link
                    target="_blank"
                    rel="noreferrer noopener"
                    href={WHITE_PAPER_URL}
                  >
                    Read more in our scientific white paper.
                  </Typography.Link>
                  <div className={styles.paragraph}>
                    <Button
                      className={styles.button}
                      type="primary"
                      onClick={() => {
                        if (userQuizToken)
                          navigate(
                            `${paths.quiz}/${userQuizToken}${paths.quizQuestions}`,
                            {
                              replace: true,
                              state: {
                                quizStatus: PsyTestStatuses.started,
                              },
                            },
                          );
                      }}
                    >
                      Start the test
                    </Button>
                  </div>
                </Col>
              </Row>
              <div>
                <img
                  className={styles.image}
                  alt="Children smiling"
                  src={backgroundImage}
                />
              </div>
            </Col>
            <div className={styles.background} />
          </Row>
        )}
      </div>
    </QuizHeaderLayout>
  );
};
