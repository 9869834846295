import { Menu, Layout } from 'antd';
import {
  TableOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { type Paths } from 'helpers/routing/paths';
import styles from './Navbar.module.scss';
import { type MenuItem } from 'shared/types';

interface AppMenuProps {
  paths: Paths;
  toggleCollapse: () => void;
  collapsed: boolean;
}

const AppMenu = ({ paths, toggleCollapse, collapsed }: AppMenuProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const handleOnClick = ({ key }: { key: string }) => {
    navigate(key);
  };
  const getDefaultSelectedKeys = () => {
    if (location.pathname.includes(paths.users)) return paths.users;
    if (location.pathname.includes(paths.companies)) return paths.companies;

    return paths.companies;
  };

  const items: MenuItem[] = [
    {
      key: paths.companies,
      label: t('Navbar.Companies'),
      icon: <TableOutlined />,
    },
    {
      key: paths.users,
      label: t('Navbar.Users'),
      icon: <UserOutlined />,
    },
  ];

  return (
    <>
      <Menu
        defaultSelectedKeys={[getDefaultSelectedKeys()]}
        mode="vertical"
        onClick={handleOnClick}
        items={items}
      />
      <div className={styles.trigger}>
        {collapsed ? (
          <MenuUnfoldOutlined className="trigger" onClick={toggleCollapse} />
        ) : (
          <MenuFoldOutlined className="trigger" onClick={toggleCollapse} />
        )}
      </div>
    </>
  );
};

interface NavbarProps {
  paths: Paths;
}

const Navbar = ({ paths }: NavbarProps) => {
  const { Sider } = Layout;
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Sider
      className={styles.sider}
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={208}
      theme="light"
    >
      <AppMenu
        paths={paths}
        key={location.pathname}
        toggleCollapse={toggleCollapse}
        collapsed={collapsed}
      />
    </Sider>
  );
};

export default Navbar;
