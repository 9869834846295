/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { axiosInstance } from 'app/api';

const UploadResults = ({ companyId }: { companyId: string }) => {
  const { t } = useTranslation();

  const customRequest = (options: any) => {
    const { file } = options;

    const fmData = new FormData();
    const config = {
      headers: { 'Content-Type': 'application/vnd.ms-excel', Accept: '*/*' },
    };
    fmData.append('file', file);
    axiosInstance.post(`/companies/${companyId}/dna-test`, fmData, config).then(
      () => message.success(`${file.name} ${t('UploadResults.uploadSuccess')}`),
      () => message.error(`${file.name} ${t('UploadResults.uploadFailed')}`),
    );
  };

  return (
    <Upload customRequest={customRequest} showUploadList={false} accept=".csv">
      <Button icon={<UploadOutlined />}>{t('UploadResults.addTests')}</Button>
    </Upload>
  );
};

export default UploadResults;
