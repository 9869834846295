import { Table } from 'antd';
import EditableCell from 'features/pages/UserDetails/Results/Tables/Editable/EditableCell';
import styles from 'features/pages/UserDetails/Results/Tables/ResultsTable.module.scss';
import { type PreparedResult } from 'features/pages/UserDetails/Results/results';
import { chunk } from 'lodash';
import { useCallback } from 'react';
import { getTraitsColumns } from './traitsTable.config';

interface IResultsTable {
  array: PreparedResult[];
  isEditing: boolean;
  formName: string;
}

const TraitsEditableTable = ({
  formName,
  isEditing,
  array = [],
}: IResultsTable) => {
  const chunkedArray =
    array.length > 20 ? chunk(array, Math.ceil(array.length / 3)) : [array];

  const prepareColumns = useCallback(
    () => getTraitsColumns(isEditing, formName),
    [formName, isEditing],
  );

  return (
    <div className={styles.container}>
      {chunkedArray.map((results, i) => (
        <Table
          key={i}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          className={i === 1 ? styles.bordered : undefined}
          columns={prepareColumns()}
          rowKey={({ name }) => name}
          dataSource={results}
          pagination={false}
        />
      ))}
    </div>
  );
};

export default TraitsEditableTable;
