import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, Modal } from 'antd';
import { WebhookEventType } from 'features/pages/AllUsers/api/allUsersAPI';
import { useAllUsersMutations } from 'features/pages/AllUsers/api/useAllUsersQuery';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { type MenuItem } from 'shared/types';

interface Props {
  rows: string[];
  isMassAction: boolean;
  rowsCount?: number;
}

export const SelectionActionsDropdown = ({
  rows,
  isMassAction,
  rowsCount,
}: Props) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const params = useParams();
  const {
    getUsersCsvSelectionMutation,
    sendWebhookEventSelectionMutation,
    getUsersCsvBulkMutation,
    sendWebhookEventBulkMutation,
  } = useAllUsersMutations();

  const parsedSearch = {
    ...queryString.parse(search),
    ...(params.companyId && { companyId: params.companyId }),
  };

  const requestSearch = `?${queryString.stringify(parsedSearch)}`;

  const items: MenuItem[] = [
    {
      key: 'generateCsvSelection',
      onClick: () => {
        if (isMassAction) {
          getUsersCsvBulkMutation.mutate(requestSearch);
        } else {
          getUsersCsvSelectionMutation.mutate({
            referenceNumbers: rows,
          });
        }
      },
      label: t('UserTable.Actions.generateCsv'),
    },
    {
      key: 'triggerNotificationSelection',
      label: t('UserTable.Actions.triggerNotification'),
      children: [
        {
          key: 'PsychologicalTestCompleted',
          onClick: () => {
            Modal.confirm({
              title: t('UserTable.Actions.confirmSelectionNotificationAction', {
                eventType: WebhookEventType.PsychologicalTestCompleted,
                qty: rowsCount,
              }),
              icon: <ExclamationCircleOutlined />,
              okText: t('UserTable.Actions.submit'),
              cancelText: t('UserTable.Actions.cancel'),
              onOk: () => {
                if (isMassAction) {
                  sendWebhookEventBulkMutation.mutate({
                    event: WebhookEventType.PsychologicalTestCompleted,
                    queryString: requestSearch,
                  });
                } else {
                  sendWebhookEventSelectionMutation.mutate({
                    event: WebhookEventType.PsychologicalTestCompleted,
                    referenceNumbers: rows,
                  });
                }
              },
            });
          },
          label: t('UserTable.Actions.Webhook.PsychologicalTestCompleted'),
        },
        {
          key: 'DNACompleted',
          onClick: () => {
            Modal.confirm({
              title: t('UserTable.Actions.confirmSelectionNotificationAction', {
                eventType: WebhookEventType.DNACompleted,
                qty: rowsCount,
              }),
              icon: <ExclamationCircleOutlined />,
              okText: t('UserTable.Actions.submit'),
              cancelText: t('UserTable.Actions.cancel'),
              onOk: () => {
                if (isMassAction) {
                  sendWebhookEventBulkMutation.mutate({
                    event: WebhookEventType.DNACompleted,
                    queryString: requestSearch,
                  });
                } else {
                  sendWebhookEventSelectionMutation.mutate({
                    event: WebhookEventType.DNACompleted,
                    referenceNumbers: rows,
                  });
                }
              },
            });
          },
          label: t('UserTable.Actions.Webhook.DNACompleted'),
        },
        {
          key: 'DNAContaminated',
          onClick: () => {
            Modal.confirm({
              title: t('UserTable.Actions.confirmSelectionNotificationAction', {
                eventType: WebhookEventType.DNAContaminated,
                qty: rowsCount,
              }),
              icon: <ExclamationCircleOutlined />,
              okText: t('UserTable.Actions.submit'),
              cancelText: t('UserTable.Actions.cancel'),
              onOk: () => {
                if (isMassAction) {
                  sendWebhookEventBulkMutation.mutate({
                    event: WebhookEventType.DNAContaminated,
                    queryString: requestSearch,
                  });
                } else {
                  sendWebhookEventSelectionMutation.mutate({
                    event: WebhookEventType.DNAContaminated,
                    referenceNumbers: rows,
                  });
                }
              },
            });
          },
          label: t('UserTable.Actions.Webhook.DNAContaminated'),
        },
        {
          key: 'DNAReceived',
          onClick: () => {
            Modal.confirm({
              title: t('UserTable.Actions.confirmSelectionNotificationAction', {
                eventType: WebhookEventType.DNAReceived,
                qty: rowsCount,
              }),
              icon: <ExclamationCircleOutlined />,
              okText: t('UserTable.Actions.submit'),
              cancelText: t('UserTable.Actions.cancel'),
              onOk: () => {
                if (isMassAction) {
                  sendWebhookEventBulkMutation.mutate({
                    event: WebhookEventType.DNAReceived,
                    queryString: requestSearch,
                  });
                } else {
                  sendWebhookEventSelectionMutation.mutate({
                    event: WebhookEventType.DNAReceived,
                    referenceNumbers: rows,
                  });
                }
              },
            });
          },
          label: t('UserTable.Actions.Webhook.DNAReceived'),
        },
        {
          key: 'DNAStarted',
          onClick: () => {
            Modal.confirm({
              title: t('UserTable.Actions.confirmSelectionNotificationAction', {
                eventType: WebhookEventType.DNAStarted,
                qty: rowsCount,
              }),
              icon: <ExclamationCircleOutlined />,
              okText: t('UserTable.Actions.submit'),
              cancelText: t('UserTable.Actions.cancel'),
              onOk: () => {
                if (isMassAction) {
                  sendWebhookEventBulkMutation.mutate({
                    event: WebhookEventType.DNAStarted,
                    queryString: requestSearch,
                  });
                } else {
                  sendWebhookEventSelectionMutation.mutate({
                    event: WebhookEventType.DNAStarted,
                    referenceNumbers: rows,
                  });
                }
              },
            });
          },
          label: t('UserTable.Actions.Webhook.DNAStarted'),
        },
      ],
    },
  ];

  return (
    <Dropdown disabled={!rows.length} menu={{ items }} trigger={['click']}>
      <Button type="primary">
        {t('UserTable.selectionActions')} ({rowsCount})
      </Button>
    </Dropdown>
  );
};
