import { useEffect } from 'react';
import { USER_QUIZ_TOKEN } from 'shared/constants/localStorageKeys';

export const useBeforeUnloadBlock = () => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      localStorage.removeItem(USER_QUIZ_TOKEN);
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};
