import { axiosInstance } from 'app/api';
import axios from 'axios';
import { type PureResult } from 'features/pages/UserDetails/Results/results';
import { type ResultInfoResponse } from 'shared/types/apiResponse';

export enum WebhookEventType {
  PsychologicalTestCompleted = 'PsychologicalTestCompleted',
  DNACompleted = 'DNACompleted',
  DNAContaminated = 'DNAContaminated',
  DNAReceived = 'DNAReceived',
  DNAStarted = 'DNAStarted',
}

export enum PsyTestStatuses {
  started = 'PsychologicalTestStarted',
  completed = 'PsychologicalTestCompleted',
}

export enum DnaTestStatuses {
  ordered = 'DnaTestOrdered',
  received = 'DnaTestReceived',
  started = 'DnaTestStarted',
  contaminated = 'DnaTestContaminated',
  completed = 'DnaTestCompleted',
}

export type PsyTestStatus =
  | (PsyTestStatuses.completed | PsyTestStatuses.started)
  | null;
export type DnaTestStatus =
  | (
      | DnaTestStatuses.ordered
      | DnaTestStatuses.received
      | DnaTestStatuses.started
      | DnaTestStatuses.contaminated
      | DnaTestStatuses.completed
    )
  | null;

export type UserItem = {
  id: string;
  gender: string;
  ageGroup: string;
  comments: string;
  psyTestStatus: PsyTestStatus;
  dnaTestStatus: DnaTestStatus;
  barcode: string | null;
  companyId: string;
  companyName: string;
  createdAt: string;
  referenceNumber: string;
  resultPayload: PureResult[];
};

export type UserItemKeys = keyof UserItem;

export type UsersData = {
  users: UserItem[];
};

interface WebhookMassNotificationResponse {
  failed: string[];
  notified: string[];
  unknowns: string[];
}

export interface DeleteUserRequirements {
  companyId: string;
  userId: string;
}

export interface UpdateUserRequirements {
  companyId: string;
  userId: string;
  field: Partial<UserItem>;
}

export interface MarkDnaAsContaminatedRequirements {
  companyId: string;
  userId: string;
  barcode?: string;
}

export interface GetUsersCsvSelectionRequirements {
  referenceNumbers: string[];
}

export interface SendNotificationSelectionRequirements {
  event: WebhookEventType;
  referenceNumbers: string[];
}

export interface SendNotificationBulkRequirements {
  event: WebhookEventType;
  queryString: string;
}

export const fetchAllUsers = async (queryString: string) => {
  try {
    return await axiosInstance.get<ResultInfoResponse<UsersData>>(
      `/users${queryString}`,
    );
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

export const deleteUser = async ({
  companyId,
  userId,
}: DeleteUserRequirements) => {
  try {
    return await axiosInstance.delete(
      `/companies/${companyId}/users/${userId}`,
    );
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

export const updateUser = async ({
  companyId,
  userId,
  field,
}: UpdateUserRequirements) => {
  try {
    return await axiosInstance.put(
      `/companies/${companyId}/users/${userId}`,
      field,
    );
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

export const markUserDnaAsContaminated = async ({
  companyId,
  userId,
  barcode,
}: MarkDnaAsContaminatedRequirements) => {
  try {
    return await axiosInstance.post(
      `/companies/${companyId}/dna-test/report-dna-contamination`,
      { referenceNumber: userId, barcode },
    );
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

export const getUsersCsvSelection = async ({
  referenceNumbers,
}: GetUsersCsvSelectionRequirements) => {
  try {
    return await axiosInstance.post<Blob>(
      '/users/csv/selection',
      {
        referenceNumbers,
      },
      {
        responseType: 'blob',
      },
    );
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

export const getUsersCsvBulk = async (queryString: string) => {
  try {
    return await axiosInstance.get<Blob>(`/users/csv/bulk${queryString}`, {
      responseType: 'blob',
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

export const sendWebhookEventSelection = async ({
  event,
  referenceNumbers,
}: SendNotificationSelectionRequirements) => {
  try {
    return await axiosInstance.post<WebhookMassNotificationResponse>(
      '/users/trigger-webhooks/selection',
      {
        referenceNumbers,
        webhookEventType: event,
      },
    );
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

export const sendWebhookEventBulk = async ({
  event,
  queryString,
}: SendNotificationBulkRequirements) => {
  try {
    return await axiosInstance.post<WebhookMassNotificationResponse>(
      `/users/trigger-webhooks/bulk${queryString}`,
      {
        webhookEventType: event,
      },
    );
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};
