import Table from 'features/common/table/Table';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'helpers/routing/paths';
import styles from './Companies.module.scss';
import { useCompaniesQuery } from './api/useCompaniesQuery';
import { convertResponseToTablePagination } from 'shared/utils/convertResponseToTablePagination';
import { useApplySearchParams } from 'shared/hooks/useSearchParamsState';
import { Button, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Filters } from 'features/common/forms/Filters/Filters';
import { getColumns } from './table/table.config';
import { companiesFilterConfig } from './filters/companiesFiltersConfig';

const Companies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { applySearchParamsValues } = useApplySearchParams();
  const { companiesQuery } = useCompaniesQuery();

  const companiesData = companiesQuery.data?.data.companies;
  const isLoading = companiesQuery.isLoading || companiesQuery.isFetching;
  const pagination = convertResponseToTablePagination(
    companiesQuery.data?.data.resultsInfo.paging,
  );

  const prepareColumns = useCallback(() => getColumns(t, navigate), []);
  const filtersConfig = companiesFilterConfig(t);

  return (
    <div className={styles.container}>
      <Typography.Title className={styles.header} level={4}>
        {t('Companies.tableTitle')}
      </Typography.Title>
      <Filters filtersConfig={filtersConfig} />
      <Table
        loading={isLoading}
        dataSource={companiesData || []}
        pagination={pagination}
        applySearchParamsValues={applySearchParamsValues}
        columns={prepareColumns()}
        header={() => {
          return (
            <Button
              onClick={() => {
                navigate(paths.addCompany);
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              {t('Companies.addCompany')}
            </Button>
          );
        }}
      />
    </div>
  );
};

export default Companies;
