import { Layout, Typography } from 'antd';
import { getYear } from 'date-fns';
import { type ReactNode } from 'react';
import styles from './QuizLayout.module.scss';

interface Props {
  children: ReactNode;
  headerText?: string;
  displayHeader?: boolean;
}

export const QuizHeaderLayout = ({
  children,
  headerText,
  displayHeader,
}: Props) => {
  const ageGroup = sessionStorage.getItem('ageGroup');

  return (
    <>
      {displayHeader && (
        <Layout.Header className={styles.header}>
          <div></div>
          {ageGroup !== '1' && (
            <div className={styles.headerText}>
              {<Typography.Text strong>{headerText}</Typography.Text>}
            </div>
          )}
        </Layout.Header>
      )}
      <Layout.Content className={styles.layout}>{children}</Layout.Content>
      <Layout.Footer className={styles.footer}>
        <Typography.Text ellipsis={true} className={styles.footerText}>
          {`©️ 2014-${getYear(
            new Date(),
          )}, Karmagenes SA, Rte de l’ile-au-Bois 1A, 1870 Monthey,
          Switzerland. Demo assessment.`}
        </Typography.Text>
      </Layout.Footer>
    </>
  );
};
