import { type CompanyData } from 'features/common/forms/CompanyForm/CompanyForm';
import { axiosInstance } from 'app/api';
import axios from 'axios';

export const saveNewCompany = async (values: CompanyData) => {
  const data = {
    name: values.name,
    phone: values.phone,
    email: values.email,
    status: 'active',
    address: {
      streetNumber: values.address.streetNumber,
      street: values.address.street,
      city: values.address.city,
      state: values.address.state,
      country: values.address.country,
      zipCode: values.address.zipCode,
    },
    purchasedProducts: ['string'],
  };
  try {
    return await axiosInstance.post('/companies', data);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};
