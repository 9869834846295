import { axiosInstance } from 'app/api';
import axios from 'axios';
import { type ResultInfoResponse } from 'shared/types/apiResponse';

export enum CompanyStatus {
  active = 'active',
  inactive = 'inactive',
}

export interface CompanyItem {
  id: string;
  name: string;
  phone: string;
  email: string;
  status: CompanyStatus.active | CompanyStatus.inactive;
  address: {
    streetNumber: string;
    street: string;
    state: string;
    zipCode: string;
    country: string;
    city: string;
  };
  comments: string;
  purchasedProducts: [];
  userCounter: number;
  createdAt: string;
  updatedAt: string;
}

export type CompanyItemKeys = keyof CompanyItem;

type CompaniesData = {
  companies: CompanyItem[];
};

export const fetchCompanies = async (queryString?: string) => {
  try {
    return await axiosInstance.get<ResultInfoResponse<CompaniesData>>(
      `/companies${queryString || ''}`,
    );
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

export const deleteCompany = async (companyId: string) => {
  try {
    return await axiosInstance.delete(`/companies/${companyId}`);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};
