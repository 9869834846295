import { useMutation } from '@tanstack/react-query';
import { type ResetCredentials, resetPassword } from '../userAPI';

export const useResetMutation = () => {
  const resetMutation = useMutation({
    mutationFn: (values: ResetCredentials) => resetPassword(values),
  });

  return {
    resetMutation,
  };
};
