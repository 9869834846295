import { Col } from 'antd';
import { type Question as QuestionType } from '../../../api/quizApi';
import { type ReactNode } from 'react';
import { QuestionWrapper } from '../../components/Questions/QuestionWrapper/QuestionWrapper';
import { QuestionImage } from '../../components/Images/QuestionImage/QuestionImage';
import { TextHighlighter } from '../../components/TextHighlighter/TextHighlighter';

interface Props {
  question: QuestionType;
  children: ReactNode;
  isSubmitDisabled: boolean;
}

export const Question = ({ question, children, isSubmitDisabled }: Props) => {
  return (
    <QuestionWrapper
      isSubmitDisabled={isSubmitDisabled}
      renderQuestion={() => (
        <>
          {question.question.imageUrl && (
            <Col span={14}>
              <QuestionImage
                src={question.question.imageUrl}
                alt={question.question.text}
              />
            </Col>
          )}
          <Col span={24}>
            <TextHighlighter>{question.question.text}</TextHighlighter>
          </Col>
        </>
      )}
    >
      {children}
    </QuestionWrapper>
  );
};
