import { type TFunction } from 'react-i18next';
import { Button, Typography } from 'antd';
import getCompanyDetailsPath from 'features/common/tables/UserTable/helpers/getCompanyDetailsPath';
import getUserDetailsPath from 'features/common/tables/UserTable/helpers/getUserDetailsPath';
import { type NavigateFunction } from 'react-router-dom';
import ActionDropdown from './ActionDropdown/ActionDropdown';
import { type ColumnsType } from 'antd/es/table';
import DnaTestStatus from './TestStatus/DnaTestStatus';
import PsyTestStatus from './TestStatus/PsyTestStatus';
import {
  type DnaTestStatus as DnaTestStatusType,
  type PsyTestStatus as PsyTestStatusType,
  type UserItem,
} from 'features/pages/AllUsers/api/allUsersAPI';

export const getColumns = (
  t: TFunction,
  navigate: NavigateFunction,
): ColumnsType<UserItem> => [
  {
    title: t('UserTable.id'),
    dataIndex: 'id',
    render: (name: string, record: UserItem) => (
      <Typography.Link
        onClick={() => {
          navigate(getUserDetailsPath(record));
        }}
        copyable
      >
        {name}
      </Typography.Link>
    ),
    width: 160,
  },
  {
    title: t('UserTable.companyId'),
    dataIndex: 'companyId',
    render: (name: string, record: UserItem) => (
      <Button
        size="small"
        onClick={() => {
          navigate(getCompanyDetailsPath(record.companyId));
        }}
        type="link"
      >
        <Typography.Link copyable>{name}</Typography.Link>
      </Button>
    ),
    width: 160,
  },
  {
    title: t('UserTable.barcode'),
    dataIndex: 'barcode',
    render: (barcode: string) => (
      <Typography.Text copyable={!!barcode}>{barcode}</Typography.Text>
    ),
    width: 160,
  },
  {
    title: t('UserTable.ageGroup'),
    dataIndex: 'ageGroup',
    render: (ageGroup: string) => {
      switch (ageGroup) {
        case '1': {
          return `${t('UserTable.AgeGroups.1')}`;
        }
        case '2': {
          return `${t('UserTable.AgeGroups.2')}`;
        }
        case '3': {
          return `${t('UserTable.AgeGroups.3')}`;
        }
        case '4': {
          return `${t('UserTable.AgeGroups.4')}`;
        }
        default:
          return '-';
      }
    },
    sorter: true,
    width: 160,
  },
  {
    title: t('UserTable.psyTestStatus'),
    dataIndex: 'psyTestStatus',
    render: (psyTestStatus: PsyTestStatusType) => (
      <PsyTestStatus psyTestStatus={psyTestStatus} />
    ),
    sorter: true,
    width: 120,
  },
  {
    title: t('UserTable.dnaTestStatus'),
    dataIndex: 'dnaTestStatus',
    render: (dnaTestStatus: DnaTestStatusType) => (
      <DnaTestStatus dnaTestStatus={dnaTestStatus} />
    ),
    sorter: true,
    width: 140,
  },
  {
    title: t('UserTable.adminComments'),
    dataIndex: 'comments',
    sorter: true,
    render: (comment: string) => (
      <Typography.Paragraph
        ellipsis={{
          rows: 2,
          tooltip: comment,
        }}
      >
        {comment}
      </Typography.Paragraph>
    ),
    width: 200,
  },
  {
    title: t('UserTable.added'),
    dataIndex: 'createdAt',
    render: (createdAt: string) => createdAt.slice(0, 10),
    width: 100,
    sorter: true,
  },
  {
    title: t('UserTable.actions'),
    dataIndex: 'numberOfUsers',
    render: (_text: string, record: UserItem) => (
      <ActionDropdown key={record.barcode} record={record} />
    ),
    width: 90,
    fixed: 'right',
  },
];
