import { Radar } from '@nivo/radar';
import { Col } from 'antd';
import { QuizHeaderLayout } from 'features/common/layout/QuizHeaderLayout';
import { PsyTestStatuses } from 'features/pages/AllUsers/api/allUsersAPI';
import { useCompanyUserQuery } from 'features/pages/CompanyDetails/api/useCompanyQuery';
import { paths } from 'helpers/routing/paths';
import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styles from '../Quiz.module.scss';
import { PageSpinner } from '../components/PageSpinner/PageSpinner';
import { useUserTokenParam } from '../shared/hooks/useUserTokenParam';
import { type QuizStatus } from '../shared/types/types';

const CONTACT_US_URL = 'https://karmagenes.co/pages/contact-us';

export const QuizCompleted = () => {
  const { companyUserQuery } = useCompanyUserQuery();
  const { userQuizToken } = useUserTokenParam();

  const { state } = useLocation();
  const quizStatus = (state as QuizStatus)?.quizStatus;

  const isLoading = companyUserQuery.isLoading;
  const isQuizCompleted =
    companyUserQuery.data?.data.psyTestCompletedAt ||
    quizStatus === PsyTestStatuses.completed;
  const isQuizStarted = companyUserQuery.data?.data.psyTestCurrentQuestion;
  const quizResults = companyUserQuery.data?.data.resultPayload?.find(
    (item) => item?.type === 'Psychological',
  );

  const results = sessionStorage.getItem('results');

  const getResultScore = (value: number) =>
    Number.isInteger(value) ? value : value.toFixed(2);

  const traits = useMemo(() => {
    const formattedResults = quizResults?.traits
      ? quizResults.traits.filter(Boolean).map((item) => ({
          name: item.name,
          value: getResultScore(item.score || 0),
        }))
      : [];

    if (results === 'complete') {
      return formattedResults;
    }

    const preferred = formattedResults.filter((item) =>
      [
        'Spontaneous',
        'Emotional',
        'Innovative',
        'Emotional',
        'Strategic',
      ].includes(item.name),
    );
    if (preferred.length === 5) {
      return preferred;
    }
    return formattedResults.slice(0, 5);
  }, [quizResults?.traits]);

  return !isLoading && !isQuizStarted && !isQuizCompleted && userQuizToken ? (
    <Navigate to={`${paths.quiz}/${userQuizToken}`} replace />
  ) : !isLoading && isQuizStarted && !isQuizCompleted && userQuizToken ? (
    <Navigate
      to={`${paths.quiz}/${userQuizToken}${paths.quizQuestions}`}
      replace
    />
  ) : (
    <QuizHeaderLayout displayHeader>
      <div className={`${styles.wrapper} ${styles.rounded}`}>
        <Col>{isLoading ? <PageSpinner /> : 'Quiz completed'}</Col>
        {results && (
          <Col>
            <Col className={`${styles.wrapper}`}>
              {results === 'basic' && (
                <p>
                  This is just a sample view of 5 personality traits. The
                  complete analysis consists of 30+ personality traits. If you
                  are interested to know more,{' '}
                  <a href={CONTACT_US_URL} target="_blank" rel="noreferrer">
                    contact us
                  </a>
                </p>
              )}
              {traits.length > 0 && (
                <Radar
                  data={traits}
                  keys={['value']}
                  indexBy="name"
                  margin={{
                    top: innerHeight * 0.05,
                    bottom: innerHeight * 0.05,
                    left: innerWidth * 0.2,
                    right: innerWidth * 0.2,
                  }}
                  valueFormat=">-.2f"
                  dotSize={5}
                  colors={{ scheme: 'paired' }}
                  animate={true}
                  height={window.innerHeight * 0.5}
                  width={window.innerWidth * 0.9}
                />
              )}
            </Col>
          </Col>
        )}
      </div>
    </QuizHeaderLayout>
  );
};
