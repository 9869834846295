import styles from './QuestionImage.module.scss';

interface Props {
  src: string;
  alt?: string;
}

export const QuestionImage = ({ src, alt }: Props) => {
  return (
    <div className={styles.imageContainer}>
      <img className={styles.image} src={src} alt={alt} />
    </div>
  );
};
