import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { type SearchParamsObject } from 'shared/types';

export const useApplySearchParams = (
  defaultSearchParams?: SearchParamsObject,
) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const searchParamsObject = queryString.parse(search);

  const applySearchParamsValues = (values: SearchParamsObject) => {
    const valuesWithDefaults = { ...values, ...defaultSearchParams };
    const { filtering, paging, sorting } = valuesWithDefaults;

    const newParams = Object.entries({
      ...filtering,
      ...paging,
      ...sorting,
    }).reduce(
      (acc, [key, { like, value }]) => {
        if (value || value === 0) {
          acc[key] = like ? `%${value}%` : value.toString();
        } else if (searchParamsObject[key]) {
          const { [key]: valueToRemove, ...rest } = acc;
          acc = rest;
        }
        return acc;
      },
      { ...searchParamsObject },
    );

    const newSearchParams = queryString.stringify({
      ...newParams,
    });

    if (search !== newSearchParams) navigate(`${pathname}?${newSearchParams}`);
  };

  return { applySearchParamsValues };
};
