import { Modal, Form, Input, type ModalProps } from 'antd';
import { useAllUsersMutations } from 'features/pages/AllUsers/api/useAllUsersQuery';
import { useModalContext } from 'features/pages/Quiz/shared/context/ModalContext';
import { useTranslation } from 'react-i18next';

export interface BarcodeModalProps {
  companyId: string;
  userId: string;
  barcode: string | null;
}

export const ChangeBarcodeModal = ({
  userId,
  companyId,
  barcode,
  ...modalProps
}: BarcodeModalProps & ModalProps) => {
  const { t } = useTranslation();
  const { updateUserMutation } = useAllUsersMutations();
  const [form] = Form.useForm<{ barcode: string }>();
  const { closeModal } = useModalContext();

  const handleOk = () => {
    form
      .validateFields()
      .then((values: { barcode: string }) => {
        updateUserMutation.mutate({
          userId,
          companyId,
          field: { barcode: values.barcode },
        });
      })
      .then(closeModal);
  };
  return (
    <Modal {...modalProps} onOk={handleOk}>
      <Form
        name="barcode-form"
        form={form}
        layout="vertical"
        initialValues={{ barcode }}
      >
        <Form.Item
          name="barcode"
          label={t('ChangeBarcodeModal.changeBarcodeField')}
          rules={[
            {
              required: true,
              message: t('ChangeBarcodeModal.barcodeIsRequired'),
            },
          ]}
        >
          <Input
            placeholder={t('ChangeBarcodeModal.changeBarcodePlaceholder')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
