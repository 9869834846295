import { useParams } from 'react-router-dom';
import { type USER_QUIZ_TOKEN } from 'shared/constants/localStorageKeys';

export const useUserTokenParam = () => {
  const { userQuizToken } = useParams<{ [USER_QUIZ_TOKEN]: string }>();
  const encodedUserToken = userQuizToken && encodeURIComponent(userQuizToken);

  return {
    userQuizToken: encodedUserToken,
  };
};
