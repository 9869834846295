import { createContext, useState, type ReactNode, useContext } from 'react';
import {
  AddCommentModal,
  type AddCommentModalProps,
} from 'features/common/tables/UserTable/AddCommentModal/AddCommentModal';
import { useTranslation } from 'react-i18next';
import {
  ChangeBarcodeModal,
  type BarcodeModalProps,
} from 'features/common/tables/UserTable/ChangeBarcodeModal/ChangeBarcodeModal';
import {
  UserQuizModal,
  type UserQuizModalProps,
} from 'features/common/tables/UserTable/UserQuizModal/UserQuizModal';

type ModalType = 'quiz' | 'comment' | 'barcode';

interface ModalTypeMap {
  quiz: UserQuizModalProps;
  comment: AddCommentModalProps;
  barcode: BarcodeModalProps;
}

interface ModalData<T extends ModalType> {
  type: T;
  data: ModalTypeMap[T];
}

interface ModalContextType<T extends ModalType> {
  modalVisible: boolean;
  modalData: ModalData<T> | null;
  openModal: <K extends ModalType>(type: K, data: ModalTypeMap[K]) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextType<ModalType>>({
  modalVisible: false,
  modalData: null,
  openModal: () => {},
  closeModal: () => {},
});

interface ModalContextProviderProps {
  children: ReactNode;
}

const ModalContextProvider = ({ children }: ModalContextProviderProps) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState<ModalData<ModalType> | null>(null);

  const openModal = <K extends ModalType>(type: K, data: ModalTypeMap[K]) => {
    setModalData({ type, data } as ModalData<ModalType>);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setModalData(null);
  };

  const renderModalContent = () => {
    if (modalData) {
      const { type, data } = modalData;

      switch (type) {
        case 'quiz':
          return (
            <UserQuizModal
              title={t('GenerateQuizLinkModal.title')}
              open={modalVisible}
              onCancel={closeModal}
              onOk={closeModal}
              {...(data as UserQuizModalProps)}
            />
          );
        case 'comment':
          return (
            <AddCommentModal
              title={t('AddCommentModal.title')}
              centered
              open={modalVisible}
              onOk={closeModal}
              onCancel={closeModal}
              width={580}
              {...(data as AddCommentModalProps)}
            />
          );
        case 'barcode':
          return (
            <ChangeBarcodeModal
              title={t('ChangeBarcodeModal.title')}
              centered
              open={modalVisible}
              onOk={closeModal}
              onCancel={closeModal}
              width={580}
              {...(data as BarcodeModalProps)}
            />
          );
        default:
      }
    }

    return null;
  };

  return (
    <ModalContext.Provider
      value={{ modalVisible, modalData, openModal, closeModal }}
    >
      {children}
      {renderModalContent()}
    </ModalContext.Provider>
  );
};

const useModalContext = () => useContext(ModalContext);

export { ModalContextProvider, useModalContext };
