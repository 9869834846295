import { axiosInstance } from 'app/api';
import axios from 'axios';
import { type UsersData } from 'features/pages/AllUsers/api/allUsersAPI';
import { type ResultInfoResponse } from 'shared/types/apiResponse';

export enum PurchasedProducts {
  dnaTest = 'dnaTest',
  psychologicalAssessment = 'psychologicalAssessment',
  testForProfessions = 'testForProfessions',
  managementStyle = 'managementStyle',
}

export interface CompanyItem {
  id: string;
  name: string;
  phone: string;
  email: string;
  address: {
    streetNumber: string;
    street: string;
    state: string;
    zipCode: string;
    country: string;
    city: string;
  };
  purchasedProducts: PurchasedProducts[];
  status: 'active' | 'inactive';
  updatedAt: string;
}

export interface CompanyData {
  company: CompanyItem;
}

export const fetchCompany = async (companyId: string) => {
  try {
    return await axiosInstance.get<CompanyData>(`/companies/${companyId}`);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

export const fetchCompanyUsers = async (
  companyId: string,
  searchParams: string,
) => {
  try {
    return await axiosInstance.get<ResultInfoResponse<UsersData>>(
      `/companies/${companyId}/users${searchParams}`,
    );
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};
