import { Col, Form, Row } from 'antd';
import { QuizHeaderLayout } from 'features/common/layout/QuizHeaderLayout';
import { PsyTestStatuses } from 'features/pages/AllUsers/api/allUsersAPI';
import { paths } from 'helpers/routing/paths';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useCompanyUserQuery } from '../../CompanyDetails/api/useCompanyQuery';
import styles from '../Quiz.module.scss';
import { useQuizMutations, useQuizQuery } from '../api/useQuizQuery';
import { PageSpinner } from '../components/PageSpinner/PageSpinner';
import { SwipeProvider } from '../components/SwipeProvider/SwipeProvider';
import { useQuizContext } from '../shared/context/QuizContext';
import { useBeforeUnloadBlock } from '../shared/hooks/useBeforeUnloadBlock';
import { useUserTokenParam } from '../shared/hooks/useUserTokenParam';
import { type QuestionForm, type QuizStatus } from '../shared/types/types';
import { QuizParentPart } from './QuizParentPart';
import { renderQuestion } from './renderQuestion';

export const questionFormInitialValues: QuestionForm = {
  answers: [],
};

export const QuizQuestions = () => {
  useBeforeUnloadBlock();
  const [form] = Form.useForm();
  const answersValue = Form.useWatch<QuestionForm['answers']>('answers', form);
  const { parentPart } = useQuizContext();
  const { userQuizToken } = useUserTokenParam();
  const { companyUserQuery } = useCompanyUserQuery();
  const { state } = useLocation();
  const quizStatus = (state as QuizStatus)?.quizStatus;
  const isStartEnabled =
    companyUserQuery.isSuccess &&
    !companyUserQuery.data.data.psyTestStatus &&
    quizStatus === PsyTestStatuses.started;
  const { startQuizQuery } = useQuizQuery({
    enabled: !!isStartEnabled,
  });
  const { answerAndGetNewQuestionMutation } = useQuizMutations();

  const startQuizData = startQuizQuery.data;
  const isStartQuizLoading = startQuizQuery.isLoading;
  const firstQuestion = startQuizData?.data.question;

  const nextQuestionData = answerAndGetNewQuestionMutation.data;
  const isNextQuestionLoading = answerAndGetNewQuestionMutation.isLoading;
  const subsequentQuestion = nextQuestionData?.data.question;

  const companyUserData = companyUserQuery.data;
  const isCompanyUserLoading = companyUserQuery.isLoading;
  const userCurrentQuestion = companyUserData?.data.psyTestCurrentQuestion;

  const currentQuestionData =
    subsequentQuestion || userCurrentQuestion || firstQuestion;

  const isQuizCompleted =
    companyUserData?.data.psyTestCompletedAt ||
    nextQuestionData?.data.noMoreQuestions;
  const isQuizStarted =
    companyUserData?.data.psyTestCurrentQuestion ||
    startQuizData?.data.question;
  const isLoading =
    isNextQuestionLoading ||
    isCompanyUserLoading ||
    (isStartEnabled && isStartQuizLoading);
  const isSubmitDisabled = isLoading || !answersValue?.length;

  useEffect(() => {
    if (isQuizCompleted) {
      companyUserQuery.refetch();
    }
  }, [isQuizCompleted]);

  const handleSubmit = (values: QuestionForm) => {
    if (companyUserData && currentQuestionData) {
      answerAndGetNewQuestionMutation.mutate({
        answerIds: Array.isArray(values.answers)
          ? values.answers
          : [values.answers],
        userId: companyUserData?.data.referenceNumber,
        companyId: companyUserData?.data.companyId,
        questionId: currentQuestionData?.question.id,
      });
    }
    form.resetFields();
  };

  return !isLoading && isQuizCompleted && userQuizToken ? (
    <Navigate
      to={`${paths.quiz}/${userQuizToken}${paths.quizCompleted}`}
      replace
      state={{
        quizStatus: PsyTestStatuses.completed,
      }}
    />
  ) : !isLoading && !isQuizStarted && !isQuizCompleted && userQuizToken ? (
    <Navigate to={`${paths.quiz}/${userQuizToken}`} replace />
  ) : !userCurrentQuestion?.question.isGuardian &&
    subsequentQuestion?.question.isGuardian &&
    !parentPart ? (
    <QuizParentPart />
  ) : (
    <QuizHeaderLayout
      displayHeader
      headerText={
        !currentQuestionData
          ? ''
          : parentPart || currentQuestionData?.question.isGuardian
          ? "Parent's part"
          : "Child's part"
      }
    >
      <div className={`${styles.wrapper} ${styles.rounded}`}>
        <Form
          className={styles.content}
          name="question"
          initialValues={questionFormInitialValues}
          onFinish={handleSubmit}
          form={form}
        >
          <Row gutter={[20, 20]} className={styles.questionsContainer}>
            {isLoading ? (
              <Col xs={24} flex={1}>
                <PageSpinner />
              </Col>
            ) : (
              <SwipeProvider
                enabled={!isSubmitDisabled}
                handleSwipe={() => {
                  form.submit();
                }}
              >
                {!!currentQuestionData &&
                  renderQuestion(
                    currentQuestionData,
                    'answers',
                    isSubmitDisabled,
                  )}
              </SwipeProvider>
            )}
          </Row>
        </Form>
      </div>
    </QuizHeaderLayout>
  );
};
