import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, ConfigProvider } from 'antd';
import { type ButtonProps } from 'antd/es/button';
import styles from './CustomButton.module.scss';

type Props = ButtonProps & {
  horozintalPadding?: number;
};

export const CustomButton = ({ horozintalPadding, ...props }: Props) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            paddingContentHorizontal: horozintalPadding,
          },
        },
      }}
    >
      <Button {...props} type="primary" className={styles.customButton}>
        <ArrowRightOutlined />
      </Button>
    </ConfigProvider>
  );
};
