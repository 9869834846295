import {
  type DnaTestStatus,
  type PsyTestStatus,
} from 'features/pages/AllUsers/api/allUsersAPI';
import { type PureResult } from 'features/pages/UserDetails/Results/results';
import { axiosInstance } from './api';

export interface UserDetailsItem {
  id: string;
  ageGroup: string;
  gender: string;
  comments: string;
  psyTestCompletedAt: string;
  dnaTestCompletedAt: string;
  psyTestCurrentQuestion: Question;
  psyTestStatus: PsyTestStatus;
  dnaTestStatus: DnaTestStatus;
  barcode: string | null;
  createdAt: string | null;
  resultPayload: PureResult[] | null;
  companyId: string;
  companyName: string;
  referenceNumber: string;
}
export interface AnswerType {
  id: string;
  text: string;
  imageUrl: string;
}

export interface QuestionType {
  id: string;
  text: string;
  isGuardian: boolean;
  type: 'singleChoice' | 'mutipleChoice' | 'slider' | 'randomOrder';
  imageUrl: string;
}

export interface Question {
  question: QuestionType;
  answers: AnswerType[];
}

export interface StartQuestionResponse {
  question: Question;
}

export interface AnswerAndGetNextQuestionResponse {
  noMoreQuestions: boolean;
  question: Question;
}

export interface StartQuizRequirements {
  companyId: string;
  userId: string;
}

export interface AnswerAndGetNextQuestionRequirements {
  companyId: string;
  userId: string;
  questionId: string;
  answerIds: string[];
}

interface GetCompanyUserRequirements {
  companyId: string;
  userId: string;
}

export const startQuiz = async ({
  companyId,
  userId,
}: StartQuizRequirements) => {
  return await axiosInstance.post<StartQuestionResponse>(
    `/companies/${companyId}/psy-test/start`,
    {
      referenceNumber: userId,
    },
  );
};

export const answerAndGetNextQuestion = async ({
  answerIds,
  companyId,
  questionId,
  userId,
}: AnswerAndGetNextQuestionRequirements) => {
  return await axiosInstance.post<AnswerAndGetNextQuestionResponse>(
    `/companies/${companyId}/psy-test/answer`,
    {
      referenceNumber: userId,
      questionId,
      answerIds,
    },
  );
};

export const fetchCompanyUser = async ({
  companyId,
  userId,
}: GetCompanyUserRequirements) => {
  return await axiosInstance.get<UserDetailsItem>(
    `/companies/${companyId}/users/${userId}`,
  );
};
