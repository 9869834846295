import { type CompanyData } from 'features/common/forms/CompanyForm/CompanyForm';
import { axiosInstance } from 'app/api';
import { PurchasedProducts } from '../CompanyDetails/api/companyAPI';
import axios from 'axios';

export const saveEditedCompany = async (
  values: CompanyData,
  companyId: string,
) => {
  const data = {
    name: values.name,
    phone: values.phone,
    email: values.email,
    status: 'active',
    address: {
      streetNumber: values.address.streetNumber,
      street: values.address.street,
      city: values.address.city,
      state: values.address.state,
      country: values.address.country,
      zipCode: values.address.zipCode,
    },
    purchasedProducts: [
      values.hasDnaTest && PurchasedProducts.dnaTest,
      values.hasPsychologicalAssessment &&
        PurchasedProducts.psychologicalAssessment,
      values.hasTestForProfessions && PurchasedProducts.testForProfessions,
      values.hasManagementStyle && PurchasedProducts.managementStyle,
    ].filter(Boolean),
  };
  try {
    return await axiosInstance.put(`/companies/${companyId}`, data);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};
