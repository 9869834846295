import { ConfigProvider, Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import styles from './QuizLayout.module.scss';

export const QuizLayout = () => {
  // const screens = Grid.useBreakpoint();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#181818',
          colorTextSecondary: '#837ACC',
          // FIX on wider screens
          // fontSize: screens.lg ? 22 : 16,
          fontSize: 16,
          fontFamily: "'Poppins', sans-serif",
        },
        components: {
          Layout: {
            colorBgHeader: '#91EE91',
            colorBgBody: '#91EE91',
          },
          Typography: {
            // FIX on wider screens
            // fontSizeHeading1: screens.lg ? 60 : screens.md ? 36 : 30,
            fontSizeHeading1: 30,
            fontWeightStrong: 500,
          },
          Button: {
            // FIX on wider screens
            // paddingContentHorizontal: screens.lg ? 60 : screens.md ? 50 : 40,
            paddingContentHorizontal: 40,
            colorBgContainerDisabled: 'rgba(0, 0, 0, 0.25)',
            colorTextDisabled: '#FFFFFF',
          },
        },
      }}
    >
      <Layout className={styles.container}>
        <Outlet />
      </Layout>
    </ConfigProvider>
  );
};
