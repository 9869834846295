import { Col, Row } from 'antd';
import { type ReactNode } from 'react';

export const AnswersWrapper = ({
  renderAdditionalData,
  renderHelperText,
  children,
}: {
  renderAdditionalData?: () => JSX.Element;
  renderHelperText?: () => JSX.Element;
  children: ReactNode;
}) => {
  return (
    <Row gutter={[16, 16]} justify="center">
      {renderAdditionalData && <Col span={24}>{renderAdditionalData()}</Col>}
      {renderHelperText && <Col span={24}>{renderHelperText()}</Col>}
      <Col span={24}>{children}</Col>
    </Row>
  );
};
