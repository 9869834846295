import { Col, Input, type InputProps, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useDebounceFunction } from 'shared/hooks/useDebounceFunction';
import { useSearchParams } from 'react-router-dom';
import { useApplySearchParams } from 'shared/hooks/useSearchParamsState';
import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
} from 'shared/constants/defaultPagination';
import { PAGING_KEYS } from 'shared/constants/apiResponseKeys';

export const FilterInput = ({
  label,
  ...inputProps
}: Omit<InputProps, 'name'> &
  Required<Pick<InputProps, 'name'>> & { label: string }) => {
  const { name } = inputProps;
  const [searchParams] = useSearchParams();
  const acquiredSearchParam = searchParams.get(name || '');
  const limit = searchParams.get(PAGING_KEYS.limit);
  const { applySearchParamsValues } = useApplySearchParams({
    paging: {
      limit: {
        value: limit ? +limit : DEFAULT_LIMIT,
      },
      offset: {
        value: DEFAULT_OFFSET,
      },
    },
  });
  const searchParamState = acquiredSearchParam?.replaceAll('%', '') ?? '';
  const [fieldValue, setFieldValue] = useState<string>(searchParamState);

  useEffect(() => {
    if (!searchParamState && fieldValue) {
      setFieldValue(searchParamState);
    }
  }, [searchParamState]);

  const [debouncedSetSearchParamsState] = useDebounceFunction(
    applySearchParamsValues,
    [searchParams.toString()],
    400,
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.value);
    debouncedSetSearchParamsState({
      filtering: {
        [e.target.name]: {
          value: e.target.value,
          like: true,
        },
      },
    });
  };

  return (
    <Row gutter={[0, 12]}>
      <Col span={24}>
        <label htmlFor={name}>{label}</label>
      </Col>
      <Col span={24}>
        <Input
          id={name}
          value={fieldValue}
          onChange={handleSearch}
          allowClear
          {...inputProps}
        />
      </Col>
    </Row>
  );
};
