import { Checkbox, Col, Form, Radio, Row, Typography } from 'antd';
import { type AnswerType } from 'features/pages/Quiz/api/quizApi';
import styles from '../Answers.module.scss';
import { type QuestionFormKeys } from 'features/pages/Quiz/shared/types/types';

interface Props {
  answers: AnswerType[];
  type: 'single' | 'multiple';
  questionFormKey: QuestionFormKeys;
}

export const TextAnswers = ({ answers, type, questionFormKey }: Props) => {
  if (type === 'single') {
    return (
      <Form.Item name={questionFormKey} className={styles.formItem}>
        <Radio.Group>
          <Row gutter={[16, 16]}>
            {answers.map((answer) => (
              <Col span={24} key={answer.id}>
                <Radio value={answer.id}>
                  <Typography.Text>{answer.text}</Typography.Text>
                </Radio>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      </Form.Item>
    );
  }

  if (type === 'multiple') {
    return (
      <Form.Item name={questionFormKey}>
        <Checkbox.Group>
          <Row gutter={[16, 16]}>
            {answers.map((answer) => (
              <Col span={24} key={answer.id}>
                <Checkbox value={answer.id}>
                  <Typography.Text>{answer.text}</Typography.Text>
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
    );
  }

  return null;
};
