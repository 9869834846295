import styles from './AnswerImage.module.scss';
import { Col, Row, Typography } from 'antd';

interface Props {
  src: string;
  answerText?: string;
  render: (data: JSX.Element) => JSX.Element;
}

export const AnswerImage = ({ src, answerText, render }: Props) => {
  const data = (
    <div className={styles.imageContainer}>
      <img className={styles.image} src={src} alt={answerText} />
    </div>
  );

  return (
    <Row justify="center">
      <Col span={24}>{render(data)}</Col>
      <Col>
        <Typography.Text className={styles.helperText}>
          {answerText}
        </Typography.Text>
      </Col>
    </Row>
  );
};
