import { clearAuthTokens, getAccessToken, setAuthTokens } from 'axios-jwt';
import { type AuthTokens } from 'features/pages/Auth/userAPI';
import {
  createContext,
  useContext,
  useMemo,
  useState,
  type ReactNode,
} from 'react';

interface UserContextValues {
  isLogged: boolean;
  logOut: () => void;
  logIn: (tokens: AuthTokens) => void;
}

const initialUserContextValues: UserContextValues = {
  isLogged: false,
  logIn: () => undefined,
  logOut: () => undefined,
};

const UserContext = createContext<UserContextValues>(initialUserContextValues);

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const authToken = getAccessToken();

  const [isLogged, setIsLogged] = useState<UserContextValues['isLogged']>(
    !!authToken,
  );

  const logOut = () => {
    clearAuthTokens();
    setIsLogged(false);
  };

  const logIn = (tokens: AuthTokens) => {
    setAuthTokens(tokens);
    setIsLogged(true);
  };

  const values: UserContextValues = useMemo(
    () => ({
      isLogged,
      logIn,
      logOut,
    }),
    [isLogged],
  );

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
