import { Modal, Typography, type ModalProps, Skeleton } from 'antd';
import { paths } from 'helpers/routing/paths';
import CryptoJS from 'crypto-js';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styles from './UserQuizModal.module.scss';
import { useUserQuizTokenQuery } from 'features/pages/CompanyDetails/api/useCompanyQuery';

export interface UserQuizModalProps {
  userId: string;
  companyId: string;
}

export const UserQuizModal = ({
  companyId,
  userId,
  ...modalProps
}: UserQuizModalProps & ModalProps) => {
  const { t } = useTranslation();
  const { userQuizTokenQuery } = useUserQuizTokenQuery({ companyId, userId });
  const userQuizToken = userQuizTokenQuery.data?.data.userTestToken;
  const isLoading = userQuizTokenQuery.isLoading;

  const userEncryptedData = CryptoJS.AES.encrypt(
    JSON.stringify({ userId, companyId, userQuizToken }),
    process.env.REACT_APP_USER_QUIZ_DATA_ENCRYPT as string,
  ).toString();

  const url = `${window.location.origin}${paths.quiz}/${encodeURIComponent(
    userEncryptedData,
  )}`;

  return (
    <Modal {...modalProps}>
      <Typography.Paragraph>
        {t('GenerateQuizLinkModal.description')}
      </Typography.Paragraph>
      {isLoading ? (
        <Skeleton active paragraph={{ rows: 8, width: '100%' }} title={false} />
      ) : (
        <Typography.Paragraph
          className={styles.link}
          copyable={{
            icon: [
              <CopyOutlined key="copyIcon" className={styles.copy} />,
              <CheckOutlined key="checkIcon" className={styles.copy} />,
            ],
          }}
        >
          {url}
        </Typography.Paragraph>
      )}
    </Modal>
  );
};
