import { useTranslation } from 'react-i18next';
import styles from './AllUsers.module.scss';
import { useAllUsersQuery } from './api/useAllUsersQuery';
import { convertResponseToTablePagination } from 'shared/utils/convertResponseToTablePagination';
import { useApplySearchParams } from 'shared/hooks/useSearchParamsState';
import { Typography } from 'antd';
import Table from 'features/common/table/Table';
import { useCallback } from 'react';
import { getColumns } from 'features/common/tables/UserTable/table.config';
import { useNavigate } from 'react-router-dom';
import { Filters } from 'features/common/forms/Filters/Filters';
import { allUsersFiltersConfig } from './filters/allUsersFiltersConfig';
import { SelectionActionsDropdown } from 'features/common/tables/UserTable/SelectionActionsDropdown/SelectionActionsDropdown';

const AllUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { applySearchParamsValues } = useApplySearchParams();
  const { allUsersQuery } = useAllUsersQuery();

  const allUsersData = allUsersQuery.data?.data.users;
  const isLoading = allUsersQuery.isLoading || allUsersQuery.isFetching;
  const pagination = convertResponseToTablePagination(
    allUsersQuery.data?.data.resultsInfo.paging,
  );

  const prepareColumns = useCallback(() => getColumns(t, navigate), []);
  const filtersConfig = allUsersFiltersConfig(t);

  return (
    <div className={styles.container}>
      <Typography.Title className={styles.header} level={4}>
        {t('AllUsers.tableTitle')}
      </Typography.Title>
      <Filters filtersConfig={filtersConfig} defaultColumnsNumber={5} />
      <Table
        header={({ rows, isMassAction, rowsCount }) => {
          return (
            <SelectionActionsDropdown
              rows={rows}
              rowsCount={rowsCount}
              isMassAction={isMassAction}
            />
          );
        }}
        selectable
        loading={isLoading}
        dataSource={allUsersData || []}
        pagination={pagination}
        applySearchParamsValues={applySearchParamsValues}
        columns={prepareColumns()}
      />
    </div>
  );
};

export default AllUsers;
