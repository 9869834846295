import { type SortingKeys, type PagingKeys } from 'shared/types/apiResponse';

export const PAGING_KEYS: Record<PagingKeys, PagingKeys> = {
  limit: 'limit',
  offset: 'offset',
  totalCount: 'totalCount',
} as const;

export const SORTING_KEYS: Record<SortingKeys, SortingKeys> = {
  orderBy: 'orderBy',
  orderDir: 'orderDir',
} as const;
