import { type FilterConfig } from 'shared/types';
import { type TFunction } from 'react-i18next';
import { CompanyStatus } from '../api/companiesAPI';
import { type CompaniesFilterKeys } from './types';

export const companiesFilterConfig = (
  t: TFunction,
): FilterConfig<CompaniesFilterKeys> => [
  {
    key: 'name',
    component: {
      type: 'input',
      inputType: 'text',
      label: t('Companies.companyName'),
      placeholder: t('Filters.companyNamePlaceholder'),
      values: '',
    },
  },
  {
    key: 'id',
    component: {
      type: 'input',
      inputType: 'text',
      label: t('Companies.companyId'),
      placeholder: t('Filters.companyIdPlaceholder'),
      values: '',
    },
  },
  {
    key: 'comments',
    component: {
      type: 'input',
      inputType: 'text',
      label: t('Companies.comments'),
      placeholder: t('Filters.companyCommentsSearchPlaceholder'),
      values: '',
    },
  },
  {
    key: 'status',
    component: {
      type: 'select',
      options: [
        {
          label: t('Companies.active'),
          value: CompanyStatus.active,
        },
        {
          label: t('Companies.inactive'),
          value: CompanyStatus.inactive,
        },
      ],
      label: t('Companies.status'),
      placeholder: t('Filters.companyStatusPlaceholder'),
      values: '',
    },
  },
];
