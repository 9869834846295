import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Alert } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { type ResetCredentials } from '../userAPI';
import styles from '../User.module.scss';
import { useResetMutation } from './useResetQuery';

export default function Reset() {
  const { t } = useTranslation();
  const { resetMutation } = useResetMutation();

  const onFinish = (values: ResetCredentials) => {
    resetMutation.mutate(values);
  };

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <Form name="reset_password" className={styles.form} onFinish={onFinish}>
          <div className={styles.headerTitle}>{t('common.appName')}</div>
          <div className={styles.formTitle}>{t('Reset.formTitle')}</div>
          {resetMutation.isError && (
            <Alert
              className={styles.alert}
              message={t('Reset.invalidEmail')}
              type="error"
              closable
            />
          )}
          {resetMutation.isSuccess && (
            <Alert
              className={styles.alert}
              message={t('Reset.success')}
              type="success"
              closable
            />
          )}
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: t('Reset.requiredUsername'),
              },
              {
                type: 'email',
                message: t('Reset.invalidEmailFormat'),
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t('Login.usernamePlaceholder')}
            />
          </Form.Item>
          <Form.Item className={styles.submitButton}>
            <Button
              loading={resetMutation.isLoading}
              block
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {t('Reset.submit')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
