import axios from 'axios';
import { BASE_URL } from 'shared/constants/baseUrl';
import { USER_QUIZ_TOKEN } from 'shared/constants/localStorageKeys';

export const axiosInstance = axios.create({ baseURL: BASE_URL });

axiosInstance.interceptors.request.use((config) => {
  config.headers = config.headers ?? {};
  const userTestToken = localStorage.getItem(USER_QUIZ_TOKEN);
  const parsedToken = userTestToken ? JSON.parse(userTestToken) : null;

  if (typeof parsedToken === 'string') {
    config.headers.Authorization = `Bearer ${parsedToken}`;
  }

  return config;
});
