import { useMutation, useQuery } from '@tanstack/react-query';
import {
  startQuiz,
  type AnswerAndGetNextQuestionRequirements,
} from './quizApi';
import { answerAndGetNextQuestion } from './quizApi';
import { QUERY_KEYS } from 'shared/constants/queryKeys';
import { Typography, message } from 'antd';
import { useQuizContext } from '../shared/context/QuizContext';

interface QuizQueryProps {
  enabled: boolean;
}

export const useQuizQuery = ({ enabled }: QuizQueryProps) => {
  const {
    userData: { companyId, userId },
  } = useQuizContext();
  const startQuizQuery = useQuery({
    queryKey: QUERY_KEYS.QUIZ.all,
    queryFn: () => startQuiz({ companyId, userId }),
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: enabled && !!companyId && !!userId,
    useErrorBoundary: true,
  });
  return {
    startQuizQuery,
  };
};

export const useQuizMutations = () => {
  const answerAndGetNewQuestionMutation = useMutation({
    mutationFn: ({
      companyId,
      userId,
      answerIds,
      questionId,
    }: AnswerAndGetNextQuestionRequirements) =>
      answerAndGetNextQuestion({
        answerIds,
        companyId,
        questionId,
        userId,
      }),
    onError: () => {
      message.error(
        <Typography.Text>Failed to answer the questions</Typography.Text>,
      );
    },
  });

  return {
    answerAndGetNewQuestionMutation,
  };
};
