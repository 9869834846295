import { Modal, Form, Input, type ModalProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAllUsersMutations } from 'features/pages/AllUsers/api/useAllUsersQuery';
import { useModalContext } from 'features/pages/Quiz/shared/context/ModalContext';

export interface AddCommentModalProps {
  userId: string;
  companyId: string;
  comments: string;
}

export const AddCommentModal = ({
  companyId,
  userId,
  comments,
  ...modalProps
}: AddCommentModalProps & ModalProps) => {
  const { t } = useTranslation();
  const { updateUserMutation } = useAllUsersMutations();
  const [form] = Form.useForm<{ comments: string }>();
  const { closeModal } = useModalContext();

  const handleOk = () => {
    form
      .validateFields()
      .then((values: { comments: string }) => {
        updateUserMutation.mutate({
          companyId,
          field: { comments: values.comments },
          userId,
        });
      })
      .then(closeModal);
  };

  return (
    <Modal {...modalProps} onOk={handleOk}>
      <Form
        name="comments-form"
        form={form}
        layout="vertical"
        initialValues={{ comments }}
      >
        <Form.Item
          name="comments"
          label={t('AddCommentModal.addCommentField')}
          rules={[
            {
              required: true,
              message: t('AddCommentModal.commentsIsRequired'),
            },
          ]}
        >
          <Input placeholder={t('AddCommentModal.commentsPlaceholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
