import { Breadcrumb, message, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { paths } from 'helpers/routing/paths';
import styles from './AddCompany.module.scss';
import { saveNewCompany } from './addCompanyAPI';
import CompanyForm, {
  type CompanyData,
} from 'features/common/forms/CompanyForm/CompanyForm';

const AddCompany = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onFinish = (values: CompanyData) => {
    saveNewCompany(values)
      .then(() => {
        message.success(t('EditCompany.requestSuccess'));
        navigate(paths.companies);
      })
      .catch(() => message.error(t('EditCompany.requestError')));
  };

  return (
    <div className={styles.container}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/">{t('Navbar.Home')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/companies">{t('Navbar.Companies')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/">{t('AddCompany.route')}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Typography.Title level={4}>{t('AddCompany.title')}</Typography.Title>
      <Typography.Text>{t('AddCompany.description')}</Typography.Text>
      <CompanyForm onFinish={onFinish} />
    </div>
  );
};

export default AddCompany;
