import { DownloadOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Tabs } from 'antd';
import {
  prepareResults,
  prepareTraitsResultsToForm,
} from 'features/pages/UserDetails/Results/helpers/prepareResults';
import { TestKeys } from 'features/pages/UserDetails/UserDetails';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import styles from '../UserDetails.module.scss';
import {
  useUserDetailsQuery,
  useUserDetailsResultsMutation,
} from '../userDetailsQuery';
import ComponentToPrint from './ComponentToPrint/ComponentToPrint';
import ResultsEditableTable from './Tables/Editable/ResultsEditableTable';
import TraitsEditableTable from './Tables/Editable/TraitsEditableTable';
import {
  findFormDataObjectChanges,
  traitsFormDataObjectChanges,
} from './helpers/handleObjectChecks';
import { type SchoolName, type ScoreName } from './results';

export type FormDataObject = Record<string, string | undefined>;

const ResultsForm = () => {
  const { t } = useTranslation();
  // TODO make better types or find another solution and change initial value in isEditing
  const { state } = useLocation();
  const { data: query } = useUserDetailsQuery();
  const { updateUserResultsQuery } = useUserDetailsResultsMutation();

  const defaultActiveKey = TestKeys.traits;
  const [isEditing, setIdEditing] = useState(
    state?.edit ? !!state.edit : false,
  );
  const [activeKey, setActiveKey] = useState<string>(defaultActiveKey);
  const [form] = Form.useForm();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (!query?.data) {
    return null;
  }

  const prepareTraits = prepareTraitsResultsToForm(
    query.data.resultPayload,
    TestKeys.traits,
  );

  const prepareProfessionsWithInterest = prepareResults(
    query.data.resultPayload?.[0]?.professionsWithInterest,
  );

  const prepareProfessionsWithoutInterest = prepareResults(
    query.data.resultPayload?.[0]?.professionsWithoutInterest,
  );

  const prepareManagementStyles = prepareResults(
    query.data.resultPayload?.[0]?.managementStyles,
  );

  const prepareSchoolSubjectsWithoutInterest = prepareResults(
    query.data.resultPayload?.[0]?.schoolSubjectsWithoutInterest,
  );

  const prepareSchoolSubjectsWithInterest = prepareResults(
    query.data.resultPayload?.[0]?.schoolSubjectsWithInterest,
  );

  const preparedTraits = prepareTraits;
  const preparedProfessionsWithInterest = prepareProfessionsWithInterest || [];
  const preparedProfessionsWithoutInterest =
    prepareProfessionsWithoutInterest || [];
  const preparedManagementStyles = prepareManagementStyles || [];
  const preparedSchoolSubjectsWithoutInterest =
    prepareSchoolSubjectsWithoutInterest || [];
  const preparedSchoolSubjectsWithInterest =
    prepareSchoolSubjectsWithInterest || [];

  const initialValues = {
    traits: prepareTraits.reduce(
      (obj, e) => ({
        ...obj,
        [e.name]: { dna: e.dna, psychological: e.psychological },
      }),
      {},
    ),
    professionsWithInterest: (
      prepareProfessionsWithInterest as ScoreName[]
    ).reduce<FormDataObject>(
      (acc, item) => ({
        ...acc,
        [item.name]: item.score?.toString(),
      }),
      {},
    ),
    professionsWithoutInterest: (
      prepareProfessionsWithoutInterest as ScoreName[]
    ).reduce<FormDataObject>(
      (acc, item) => ({
        ...acc,
        [item.name]: item.score?.toString(),
      }),
      {},
    ),
    managementStyles: (
      prepareManagementStyles as ScoreName[]
    ).reduce<FormDataObject>(
      (acc, item) => ({
        ...acc,
        [item.name]: item.score?.toString(),
      }),
      {},
    ),
    schoolSubjectsWithInterest: (
      prepareSchoolSubjectsWithInterest as SchoolName[]
    ).reduce<FormDataObject>(
      (acc, item) => ({
        ...acc,
        [item.name]: item.score?.toString(),
      }),
      {},
    ),
    schoolSubjectsWithoutInterest: (
      prepareSchoolSubjectsWithoutInterest as SchoolName[]
    ).reduce<FormDataObject>(
      (acc, item) => ({
        ...acc,
        [item.name]: item.score?.toString(),
      }),
      {},
    ),
  };

  const showModal = () => {
    Modal.warning({
      title: t('UserDetails.modal_tab_switch.title'),
      okText: t('UserDetails.modal_tab_switch.ok_button'),
    });
  };

  const disabledAllButtons = activeKey === 'traits' && !preparedTraits.length;

  const onSubmit = async (value: typeof initialValues) => {
    if (isEditing) {
      return;
    }
    const managementStyles = findFormDataObjectChanges(
      initialValues.managementStyles,
      value.managementStyles,
    );
    const schoolSubjectsWithInterest = findFormDataObjectChanges(
      initialValues.schoolSubjectsWithInterest,
      value.schoolSubjectsWithInterest,
    );
    const schoolSubjectsWithoutInterest = findFormDataObjectChanges(
      initialValues.schoolSubjectsWithoutInterest,
      value.schoolSubjectsWithoutInterest,
    );
    const professionsWithInterest = findFormDataObjectChanges(
      initialValues.professionsWithInterest,
      value.professionsWithInterest,
    );
    const professionsWithoutInterest = findFormDataObjectChanges(
      initialValues.professionsWithoutInterest,
      value.professionsWithoutInterest,
    );
    const traits = traitsFormDataObjectChanges(
      initialValues.traits,
      value.traits,
    );

    updateUserResultsQuery.mutate({
      payload: {
        ...traits,
        managementStyles,
        professionsWithInterest,
        professionsWithoutInterest,
        schoolSubjectsWithInterest,
        schoolSubjectsWithoutInterest,
      },
    });
  };

  const Traits = (
    <TraitsEditableTable
      formName={TestKeys.traits}
      isEditing={isEditing}
      array={preparedTraits}
    />
  );

  const ProfessionsWithInterest = (
    <ResultsEditableTable
      formName={TestKeys.professionsWithInterest}
      isEditing={isEditing}
      array={preparedProfessionsWithInterest}
    />
  );

  const ProfessionsWithoutInterest = (
    <ResultsEditableTable
      formName={TestKeys.professionsWithoutInterest}
      isEditing={isEditing}
      array={preparedProfessionsWithoutInterest}
    />
  );

  const SchoolSubjectsWithInterest = (
    <ResultsEditableTable
      formName={TestKeys.schoolSubjectsWithInterest}
      isEditing={isEditing}
      array={preparedSchoolSubjectsWithInterest}
    />
  );

  const SchoolSubjectsWithoutInterest = (
    <ResultsEditableTable
      formName={TestKeys.schoolSubjectsWithoutInterest}
      isEditing={isEditing}
      array={preparedSchoolSubjectsWithoutInterest}
    />
  );

  const ManagementStyle = (
    <ResultsEditableTable
      formName={TestKeys.managementStyles}
      isEditing={isEditing}
      array={preparedManagementStyles}
    />
  );

  const handleTabSwitch = (key: string) => {
    if (isEditing) {
      showModal();
    } else {
      setActiveKey(key);
    }
  };

  return (
    <div className={styles.table}>
      <div className={styles.header}>
        <div>{t('UserDetails.tableTitle')}</div>
        <div>
          <Button
            disabled={disabledAllButtons}
            onClick={() => {
              form.submit();
              setIdEditing(!(isEditing && query.data.resultPayload));
            }}
            type="primary"
          >
            {t(
              isEditing && query.data.resultPayload
                ? 'UserDetails.save'
                : 'UserDetails.edit',
            )}
          </Button>
          {!isEditing && (
            <Button
              disabled={disabledAllButtons}
              style={{
                background: '#FD7446',
                borderColor: '#FD7446',
                marginLeft: '20px',
              }}
              color="green"
              icon={<DownloadOutlined />}
              onClick={handlePrint}
              type="primary"
            >
              {t('UserDetails.saveAsPdf')}
            </Button>
          )}
        </div>
      </div>
      <Form
        key="edit-results-form"
        form={form}
        component={false}
        initialValues={initialValues}
        onFinish={onSubmit}
      >
        <Tabs
          defaultActiveKey={defaultActiveKey}
          activeKey={activeKey}
          onTabClick={handleTabSwitch}
          items={[
            {
              key: TestKeys.traits,
              disabled: !preparedTraits.length,
              label: t('UserDetails.traits'),
              children: Traits,
            },
            {
              key: TestKeys.professionsWithInterest,
              disabled: !preparedProfessionsWithInterest.length,
              label: t('UserDetails.professionsWithInterest'),
              children: ProfessionsWithInterest,
            },
            {
              key: TestKeys.professionsWithoutInterest,
              disabled: !preparedProfessionsWithoutInterest.length,
              label: t('UserDetails.professionsWithoutInterest'),
              children: ProfessionsWithoutInterest,
            },
            {
              key: TestKeys.managementStyles,
              disabled: !preparedManagementStyles.length,
              label: t('UserDetails.managementStyles'),
              children: ManagementStyle,
            },
            {
              key: TestKeys.schoolSubjectsWithInterest,
              disabled: !preparedSchoolSubjectsWithInterest.length,
              label: t('UserDetails.schoolSubjectsWithInterest'),
              children: SchoolSubjectsWithInterest,
            },
            {
              key: TestKeys.schoolSubjectsWithoutInterest,
              disabled: !preparedSchoolSubjectsWithoutInterest.length,
              label: t('UserDetails.schoolSubjectsWithoutInterest'),
              children: SchoolSubjectsWithoutInterest,
            },
          ]}
        />
      </Form>
      {!isEditing && (
        <div className={styles.componentToPrint}>
          <ComponentToPrint
            item={query.data}
            ManagementStyle={ManagementStyle}
            Traits={Traits}
            ProfessionsWithoutInterest={ProfessionsWithoutInterest}
            ProfessionsWithInterest={ProfessionsWithInterest}
            SchoolSubjectWithInterest={SchoolSubjectsWithInterest}
            SchoolSubjectWithoutInterest={SchoolSubjectsWithoutInterest}
            key="component-to-print"
            ref={componentRef}
          />
        </div>
      )}
    </div>
  );
};

export default ResultsForm;
