import { ErrorPage } from 'features/common/errors/ErrorPage/ErrorPage';
import { Layout } from 'features/common/layout/Layout';
import { QuizLayout } from 'features/common/layout/QuizLayout';
import AddCompany from 'features/pages/AddCompany/AddCompany';
import AllUsers from 'features/pages/AllUsers/AllUsers';
import Login from 'features/pages/Auth/Login/Login';
import { LoginRedirect } from 'features/pages/Auth/Login/LoginRedirect';
import { LoginWrapper } from 'features/pages/Auth/Login/LoginWrapper';
import Reset from 'features/pages/Auth/Reset/Reset';
import Companies from 'features/pages/Companies/Companies';
import CompanyDetails from 'features/pages/CompanyDetails/CompanyDetails';
import EditCompany from 'features/pages/EditCompany/EditCompany';
import { QuizCompleted } from 'features/pages/Quiz/QuizCompleted/QuizCompleted';
import { QuizQuestions } from 'features/pages/Quiz/QuizQuestions/QuizQuestions';
import { QuizStart } from 'features/pages/Quiz/QuizStart/QuizStart';
import { QuizContextProvider } from 'features/pages/Quiz/shared/context/QuizContext';
import UserDetails from 'features/pages/UserDetails/UserDetails';
import ProtectedAdminPanelRoute from 'helpers/routing/ProtectedAdminPanelRoute';
import { ProtectedQuizRoute } from 'helpers/routing/ProtectedQuizRoute';
import { paths } from 'helpers/routing/paths';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { UserContextProvider } from 'shared/context/UserContext';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path={`${paths.login}/*`}
        element={
          <Routes>
            <Route element={<LoginWrapper />}>
              <Route index element={<Login />} />
              <Route path={paths['2fa']} element={<LoginRedirect />} />
            </Route>
          </Routes>
        }
      />
      <Route path={paths.reset} element={<Reset />} />
      <Route element={<ProtectedAdminPanelRoute redirectPath={paths.login} />}>
        <Route element={<Layout />}>
          <Route path={paths.main} element={<Companies />} />
          <Route path={paths.companies} element={<Companies />} />
          <Route path={paths.companyDetails} element={<CompanyDetails />} />
          <Route path={paths.userDetails} element={<UserDetails />} />
          <Route path={paths.companyDetailsEdit} element={<EditCompany />} />
          <Route path={paths.addCompany} element={<AddCompany />} />
          <Route path={paths.users} element={<AllUsers />} />
        </Route>
      </Route>
      <Route
        path={`${paths.quizUser}/*`}
        element={
          <Routes>
            <Route
              element={
                <ErrorBoundary
                  fallbackRender={(props) => {
                    const { error } = props;
                    return <ErrorPage error={error} />;
                  }}
                >
                  <QuizContextProvider>
                    <ProtectedQuizRoute />
                  </QuizContextProvider>
                </ErrorBoundary>
              }
            >
              <Route element={<QuizLayout />}>
                <Route index element={<QuizStart />} />
                <Route path={paths.quizQuestions} element={<QuizQuestions />} />
                <Route path={paths.quizCompleted} element={<QuizCompleted />} />
              </Route>
              <Route path="*" element={<ErrorPage status={404} />} />
            </Route>
          </Routes>
        }
      />
      <Route path="*" element={<ErrorPage status={404} />} />
    </>,
  ),
);

function App() {
  return (
    <UserContextProvider>
      <Suspense fallback="loading">
        <RouterProvider router={router} />
      </Suspense>
    </UserContextProvider>
  );
}

export default App;
