import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Form, Input } from 'antd';
import { ErrorAlert } from 'features/common/errors/ErrorAlert/ErrorAlert';
import { paths } from 'helpers/routing/paths';
import { Trans, useTranslation } from 'react-i18next';
import { useErrorMessage } from 'shared/hooks/useErrorMessage';
import styles from '../User.module.scss';
import { type UserCredentials } from '../userAPI';
import { useSend2FATokenMutation } from './useSend2FATokenMutation';

export default function Login() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { send2FATokenMutation } = useSend2FATokenMutation();

  const { errorMessage } = useErrorMessage({
    error: send2FATokenMutation.error,
    customStatusErrorMessageMap: {
      401: {
        message: t('Login.invalidCredentials'),
      },
      403: {
        message: t('Login.noAccount'),
      },
    },
  });

  const onFinish = (values: UserCredentials) => {
    send2FATokenMutation.mutate(values);
  };

  return (
    <Form
      form={form}
      name="login"
      className={styles.form}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <div className={styles.headerTitle}>{t('common.appName')}</div>
      <div className={styles.formTitle}>{t('Login.formTitle')}</div>
      {send2FATokenMutation.isError && (
        <ErrorAlert errorMessage={errorMessage} />
      )}
      {send2FATokenMutation.isSuccess && (
        <Alert
          data-testid="success-alert"
          className={styles.alert}
          message={
            <Trans
              i18nKey="Login.authorizationLinkSent"
              values={{ email: form.getFieldValue('companyId') }}
              components={{ strong: <strong /> }}
            />
          }
          type="success"
          showIcon
        />
      )}
      <Form.Item
        name="companyId"
        rules={[
          {
            required: true,
            message: t('Login.requiredUsername'),
          },
          {
            type: 'string',
            message: t('Login.invalidEmailFormat'),
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder={t('Login.usernamePlaceholder')}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: t('Login.requiredPassword'),
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder={t('Login.passwordPlaceholder')}
        />
      </Form.Item>
      <Form.Item>
        <Form.Item
          className={styles.loginFormRemember}
          name="remember"
          valuePropName="checked"
        >
          <Checkbox>{t('Login.remember')}</Checkbox>
        </Form.Item>
        <a className={styles.loginFormForgot} href={paths.reset}>
          {t('Login.forgotCredentials')}
        </a>
      </Form.Item>
      <Form.Item className={styles.submitButton}>
        <Button
          block
          type="primary"
          htmlType="submit"
          loading={send2FATokenMutation.isLoading}
          disabled={send2FATokenMutation.isLoading}
        >
          {t('Login.submit')}
        </Button>
      </Form.Item>
    </Form>
  );
}
