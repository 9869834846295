import { Button, Col, Row, Typography, theme } from 'antd';
import { QuizHeaderLayout } from 'features/common/layout/QuizHeaderLayout';
import styles from '../Quiz.module.scss';
import { useQuizContext } from '../shared/context/QuizContext';

export const QuizParentPart = () => {
  const { token } = theme.useToken();
  const { setParentPart } = useQuizContext();

  return (
    <QuizHeaderLayout displayHeader>
      <div className={`${styles.wrapper} ${styles.rounded}`}>
        <Row gutter={[0, 20]} wrap={true} className={styles.content}>
          <>
            <Col>
              <Typography.Title className={styles.title}>
                <span
                  style={{ color: token.colorTextSecondary }}
                  className={styles.boldTitle}
                >
                  Thank you,
                </span>
              </Typography.Title>
              <Typography.Paragraph>
                Thank you, it is now time for your parent/legal guardian to
                <br />
                start answering the assessment.
              </Typography.Paragraph>
            </Col>
            <Col className={styles.buttonContainer}>
              <Button
                className={styles.button}
                type="primary"
                size="middle"
                onClick={() => {
                  setParentPart(true);
                }}
              >
                START!
              </Button>
            </Col>
          </>
        </Row>
      </div>
    </QuizHeaderLayout>
  );
};
