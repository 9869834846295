import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { QUERY_KEYS } from 'shared/constants/queryKeys';
import {
  fetchUser,
  updateFinalResults,
  type UpdateFinalResultsPayload,
} from './userAPI';

export const useUserDetailsQuery = () => {
  const { companyId, referenceNumber } = useParams<{
    companyId: string;
    referenceNumber: string;
  }>();

  const isEnabled = !!companyId && !!referenceNumber;

  const userDetailsQuery = useQuery({
    enabled: isEnabled,
    queryKey: QUERY_KEYS.USER_DETAILS.details(companyId, referenceNumber),
    queryFn: () =>
      isEnabled
        ? fetchUser(companyId, referenceNumber).then((response) => response)
        : Promise.reject(new Error('Missing one of the required parameters')),
    staleTime: 1000 * 60 * 10,
  });
  return userDetailsQuery;
};

interface UpdateUserResultsMutationFnProps {
  payload: UpdateFinalResultsPayload;
}

export const useUserDetailsResultsMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { companyId, referenceNumber } = useParams<{
    companyId?: string;
    referenceNumber?: string;
  }>();

  const isEnabled = !!companyId && !!referenceNumber;

  const updateUserResultsQuery = useMutation({
    mutationFn: ({ payload }: UpdateUserResultsMutationFnProps) =>
      isEnabled
        ? updateFinalResults(companyId, referenceNumber, payload)
        : Promise.reject(new Error('Missing required parameters')),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.USER_DETAILS.all,
      });
      message.success(t('UpdateResults.update_result_success'));
    },

    onError: () => message.error(t('UpdateResults.could_not_update_results')),
  });

  return { updateUserResultsQuery };
};
