import { useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';

export const useDebounceFunction = <T>(
  func: (args: T) => unknown,
  deps: Array<string | number>,
  time: number,
) => {
  const debouncedFunction = useMemo(() => debounce(func, time), [...deps]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      debouncedFunction.cancel();
    };
  }, []);

  return [debouncedFunction];
};
