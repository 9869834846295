import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useGetAuthTokenWith2FAQuery } from './useGetAuthTokenWith2FAQuery';
import { paths } from 'helpers/routing/paths';
import { Col, Row, Spin, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useUserContext } from 'shared/context/UserContext';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../User.module.scss';
import { useErrorMessage } from 'shared/hooks/useErrorMessage';
import { ErrorAlert } from 'features/common/errors/ErrorAlert/ErrorAlert';
import queryString from 'query-string';

export const LoginRedirect = () => {
  const { t } = useTranslation();
  const { getAuthTokenWith2FAQuery } = useGetAuthTokenWith2FAQuery();
  const { isLogged } = useUserContext();
  const navigate = useNavigate();
  const { errorMessage } = useErrorMessage({
    error: getAuthTokenWith2FAQuery.error,
    customStatusErrorMessageMap: {
      403: {
        message: [
          t('LoginRedirect.invalidToken'),
          t('LoginRedirect.noAccount'),
        ],
      },
    },
  });

  const { search } = useLocation();
  const parsedSearch = queryString.parse(search);
  const hasTokenSearchParam = !!parsedSearch.token;

  const isError = getAuthTokenWith2FAQuery.isError;
  const isLoading = getAuthTokenWith2FAQuery.isLoading && !isLogged && !isError;
  const isSuccess = getAuthTokenWith2FAQuery.isSuccess;

  useEffect(() => {
    if (!isLoading && isSuccess) {
      navigate(paths.main, { replace: true });
    }
  }, [isLoading, isSuccess, navigate]);

  return hasTokenSearchParam ? (
    <Row gutter={[20, 20]} className={styles.form}>
      {isLoading ? (
        <>
          <Col span={24}>
            <div className={styles.headerTitleRedirect}>
              {t('common.appName')}
            </div>
          </Col>
          <Col span={24}>
            <Spin size="large" />
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>
              {t('LoginRedirect.redirecting')}
            </Typography.Title>
          </Col>
        </>
      ) : (
        isError && (
          <>
            <Col span={24}>
              <ErrorAlert errorMessage={errorMessage} />
            </Col>
            <Col span={24}>
              <Link to={paths.companies} replace>
                <ArrowLeftOutlined /> {t('LoginRedirect.backToAuthentication')}
              </Link>
            </Col>
          </>
        )
      )}
    </Row>
  ) : (
    <Navigate to={paths.login} replace />
  );
};
