import { Col, ConfigProvider, type ThemeConfig } from 'antd';
import { type ReactNode } from 'react';

interface Props {
  answerId: string;
  color: string;
  themeConfig?: ThemeConfig;
  children: ReactNode;
}

export const ImageAnswerWrapper = ({
  answerId,
  color,
  themeConfig = {},
  children,
}: Props) => {
  // const { portrait } = useScreenOrientation();
  // const { md } = Grid.useBreakpoint();

  return (
    // FIX on wider screens
    // <Col key={answerId} flex={portrait && !md ? '0 0 50%' : '1 1 19%'}>
    <Col key={answerId} span={12}>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              borderRadius: 0,
              lineWidth: 5,
              colorBorder: color,
              colorPrimaryHover: color,
              colorPrimaryActive: color,
            },
          },
          ...{ ...themeConfig },
        }}
      >
        {children}
      </ConfigProvider>
    </Col>
  );
};
