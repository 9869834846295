import { axiosInstance } from 'app/api';
import axios from 'axios';

export interface GetUserQuizTokenRequirements {
  companyId: string;
  userId: string;
}
interface UserQuizToken {
  userTestToken: string;
}

export interface UserCredentials {
  companyId: string;
  password: string;
  remember: boolean;
}

export interface ResetCredentials {
  username: string;
}

export interface GetAuthTokenWith2FARequirements {
  twoFactorTokenId: string;
}

export interface AuthTokens {
  accessToken: string;
  refreshToken: string;
}

export const resetPassword = async (values: ResetCredentials) => {
  try {
    return await axiosInstance.post('/auth/reset', values);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

export const getUserQuizToken = async ({
  companyId,
  userId,
}: GetUserQuizTokenRequirements) => {
  return await axiosInstance.post<UserQuizToken>(`auth/test-token`, {
    referenceNumber: userId,
    companyId,
  });
};

export const send2FAToken = async (values: UserCredentials) => {
  return await axiosInstance.post('/auth/token/2fa/send', values);
};

export const getAuthTokenWith2FA = async (
  values: GetAuthTokenWith2FARequirements,
) => {
  const response = await axiosInstance.post<AuthTokens>(
    '/auth/token/2fa/use',
    values,
  );
  return response.data;
};
