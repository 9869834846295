import CryptoJS from 'crypto-js';
import { useQuizContext } from 'features/pages/Quiz/shared/context/QuizContext';
import { useUserTokenParam } from 'features/pages/Quiz/shared/hooks/useUserTokenParam';
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { errorMessageKeys } from 'shared/hooks/useErrorMessage';

export const ProtectedQuizRoute = () => {
  const { userQuizToken } = useUserTokenParam();
  const { updateUserData } = useQuizContext();

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (userQuizToken && process.env.REACT_APP_USER_QUIZ_DATA_ENCRYPT) {
      const quizUserData = CryptoJS.AES.decrypt(
        decodeURIComponent(userQuizToken),
        process.env.REACT_APP_USER_QUIZ_DATA_ENCRYPT,
      );

      try {
        const userEncryptedData = JSON.parse(
          quizUserData.toString(CryptoJS.enc.Utf8),
        );

        const ageGroup = searchParams.get('ageGroup');
        const results = searchParams.get('results');
        if (ageGroup) {
          sessionStorage.setItem('ageGroup', ageGroup);
        }
        if (results) {
          sessionStorage.setItem('results', results);
        }

        updateUserData({
          ...userEncryptedData,
        });
      } catch {
        throw new Error(errorMessageKeys.userQuizToken);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, userQuizToken]);

  return <Outlet />;
};
