export const downloadFile = (blobData: Blob, fileName: string) => {
  // Create a URL to the blob
  const url = window.URL.createObjectURL(blobData);

  // Create a new anchor element
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);

  // Append the anchor element to the DOM
  document.body.appendChild(link);

  // Simulate a click on the anchor element
  link.click();

  // Remove the anchor element and revoke the Blob object
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
