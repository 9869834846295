import { Button, Col, Grid, Row, Typography } from 'antd';
import styles from './Filters.module.scss';
import { useTranslation } from 'react-i18next';
import {
  type SearchParamsObject,
  type FilterConfig,
  type FilterFieldConfig,
} from 'shared/types';
import { renderFilterField } from './utils/renderFilterFIeld';
import { useApplySearchParams } from 'shared/hooks/useSearchParamsState';
import { ClearOutlined } from '@ant-design/icons';

type Props<T extends string> = {
  filtersConfig: FilterConfig<T>;
  defaultColumnsNumber?: number;
};

const getColumnPercentageWidth = (columns: number) => `${(1 / columns) * 100}%`;

const FilterColumn = <T extends string>({
  filter,
  columns,
}: {
  filter: FilterFieldConfig<T>;
  columns: number;
}) => {
  const { xxl } = Grid.useBreakpoint();

  return xxl ? (
    <Col
      flex={`0 0 ${getColumnPercentageWidth(columns)}`}
      style={{
        width: getColumnPercentageWidth(columns),
      }}
      key={filter.key}
    >
      {renderFilterField(filter)}
    </Col>
  ) : (
    <Col xl={6} xs={12} md={8} key={filter.key}>
      {renderFilterField(filter)}
    </Col>
  );
};

export const Filters = <T extends string>({
  filtersConfig,
  defaultColumnsNumber: columns = 4,
}: Props<T>) => {
  const { t } = useTranslation();
  const { applySearchParamsValues } = useApplySearchParams();

  const defaultFilterValues: SearchParamsObject['filtering'] =
    filtersConfig.reduce<SearchParamsObject['filtering']>(
      (acc, filter) => ({
        ...acc,
        [filter.key]: {
          value: filter.component.values,
        },
      }),
      {},
    );

  return (
    <div className={styles.filter}>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Typography.Title className={styles.header} level={5}>
            {t('Filters.filters')}
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Row gutter={[12, 12]}>
            {filtersConfig.map((filter) => {
              return (
                <FilterColumn<T>
                  key={filter.key}
                  filter={filter}
                  columns={columns}
                />
              );
            })}
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="start">
            <Col>
              <Button
                icon={<ClearOutlined />}
                type="primary"
                onClick={() => {
                  applySearchParamsValues({
                    filtering: defaultFilterValues,
                  });
                }}
              >
                {t('Filters.resetAllFilter')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
