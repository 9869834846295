import { type FilterConfig } from 'shared/types';
import { type TFunction } from 'react-i18next';
import { DnaTestStatuses, PsyTestStatuses } from '../api/allUsersAPI';
import { type AllUsersFilterKeys } from './types';

export const allUsersFiltersConfig = (
  t: TFunction,
): FilterConfig<AllUsersFilterKeys> => [
  {
    key: 'referenceNumber',
    component: {
      type: 'input',
      inputType: 'text',
      label: t('UserTable.id'),
      placeholder: t('Filters.userIdSearchPlaceholder'),
      values: '',
    },
    like: false,
  },
  {
    key: 'barcode',
    component: {
      type: 'input',
      inputType: 'text',
      label: t('UserTable.barcode'),
      placeholder: t('Filters.barcodeSearchPlaceholder'),
      values: '',
    },
    like: false,
  },
  {
    key: 'comments',
    component: {
      type: 'input',
      inputType: 'text',
      label: t('UserTable.adminComments'),
      placeholder: t('Filters.adminCommentsSearchPlaceholder'),
      values: '',
    },
    like: false,
  },
  {
    key: 'psyTestStatus',
    component: {
      type: 'select',
      options: [
        {
          label: t(`UserTable.TestStatus.psyTestStarted`),
          value: PsyTestStatuses.started,
        },
        {
          label: t(`UserTable.TestStatus.psyTestCompleted`),
          value: PsyTestStatuses.completed,
        },
      ],
      label: t('UserTable.psyTestStatus'),
      placeholder: t('Filters.psyTestStatusPlaceholder'),
      values: '',
    },
  },
  {
    key: 'dnaTestStatus',
    component: {
      type: 'select',
      options: [
        {
          label: t(`UserTable.TestStatus.dnaTestOrdered`),
          value: DnaTestStatuses.ordered,
        },
        {
          label: t(`UserTable.TestStatus.dnaTestReceived`),
          value: DnaTestStatuses.received,
        },
        {
          label: t(`UserTable.TestStatus.dnaTestStarted`),
          value: DnaTestStatuses.started,
        },
        {
          label: t(`UserTable.TestStatus.dnaTestContaminated`),
          value: DnaTestStatuses.contaminated,
        },
        {
          label: t(`UserTable.TestStatus.dnaTestCompleted`),
          value: DnaTestStatuses.completed,
        },
      ],
      label: t('UserTable.dnaTestStatus'),
      placeholder: t('Filters.dnaTestStatusPlaceholder'),
      values: '',
    },
  },
];
