import { Breadcrumb, Descriptions, Typography, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { paths } from 'helpers/routing/paths';
import UploadResults from 'features/componants/UploadResults/UploadResults';
import styles from './CompanyDetails.module.scss';
import { convertResponseToTablePagination } from 'shared/utils/convertResponseToTablePagination';
import { useApplySearchParams } from 'shared/hooks/useSearchParamsState';
import {
  useCompanyDetailsQuery,
  useCompanyUsersQuery,
} from './api/useCompanyQuery';
import Table from 'features/common/table/Table';
import { getColumns } from 'features/common/tables/UserTable/table.config';
import { SelectionActionsDropdown } from 'features/common/tables/UserTable/SelectionActionsDropdown/SelectionActionsDropdown';
import { Filters } from 'features/common/forms/Filters/Filters';
import { allUsersFiltersConfig } from '../AllUsers/filters/allUsersFiltersConfig';

const CompanyDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { companyDetailsQuery } = useCompanyDetailsQuery();
  const { companyUsersQuery } = useCompanyUsersQuery();
  const { applySearchParamsValues } = useApplySearchParams();

  const filtersConfig = allUsersFiltersConfig(t);

  const companyUsersData = companyUsersQuery.data?.data.users;
  const isCompanyUsersLoading =
    companyUsersQuery.isLoading || companyUsersQuery.isFetching;
  const pagination = convertResponseToTablePagination(
    companyUsersQuery.data?.data.resultsInfo.paging,
  );
  const companyDetailsData = companyDetailsQuery.data?.data.company;
  const isCompanyDetailsLoading =
    companyDetailsQuery.isLoading || companyDetailsQuery.isFetching;
  const prepareColumns = useCallback(() => getColumns(t, navigate), []);

  if (isCompanyDetailsLoading || !companyDetailsData) return null;

  return (
    <div className={styles.container}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/">{t('Navbar.Home')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/companies">{t('Navbar.Companies')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/">{companyDetailsData.name}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Typography.Title className={styles.header} level={4}>
        {companyDetailsData.name}
      </Typography.Title>
      <Typography.Text>{`${t('CompanyDetails.description')} ${
        companyDetailsData.name
      }`}</Typography.Text>
      <div className={styles.descriptions}>
        <div className={styles.uploadResults}>
          <UploadResults companyId={companyDetailsData.id} />
        </div>
        <Button
          onClick={() => {
            navigate(`${paths.companies}/${companyDetailsData.id}/edit`);
          }}
          className={styles.editButton}
          type="link"
        >
          {`${t('CompanyDetails.edit')}`}
        </Button>
        <Descriptions size="small" title={t('CompanyDetails.title')} column={4}>
          <Descriptions.Item
            labelStyle={{ minWidth: '110px' }}
            label={t('CompanyDetails.name')}
          >
            {companyDetailsData.name}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ minWidth: '100px' }}
            label={t('CompanyDetails.country')}
          >
            {companyDetailsData.address.country}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ minWidth: '70px' }}
            label={t('CompanyDetails.id')}
          >
            {companyDetailsData.id}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ minWidth: '110px' }}
            label={t('CompanyDetails.email')}
          >
            {companyDetailsData.email}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ minWidth: '100px' }}
            label={t('CompanyDetails.city')}
          >
            {companyDetailsData.address.city}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ minWidth: '110px' }}
            label={t('CompanyDetails.phoneNumber')}
          >
            {companyDetailsData.phone}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ minWidth: '100px' }}
            label={t('CompanyDetails.zipCode')}
          >
            {companyDetailsData.address.zipCode}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <Filters filtersConfig={filtersConfig} defaultColumnsNumber={5} />
      <div className={styles.table}>
        <Table
          selectable
          loading={isCompanyUsersLoading}
          dataSource={companyUsersData || []}
          pagination={pagination}
          applySearchParamsValues={applySearchParamsValues}
          columns={prepareColumns()}
          header={({ rows, isMassAction, rowsCount }) => {
            return (
              <SelectionActionsDropdown
                rows={rows}
                rowsCount={rowsCount}
                isMassAction={isMassAction}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default CompanyDetails;
