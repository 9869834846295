import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'shared/constants/queryKeys';
import { getAuthTokenWith2FA } from '../userAPI';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useUserContext } from 'shared/context/UserContext';
import { errorMessageKeys } from 'shared/hooks/useErrorMessage';

export const useGetAuthTokenWith2FAQuery = () => {
  const { search } = useLocation();
  const parsedSearch = queryString.parse(search);
  const { logIn } = useUserContext();

  const getAuthTokenWith2FAQuery = useQuery({
    queryKey: QUERY_KEYS.USE_2FA_TOKEN.all,
    queryFn: () =>
      typeof parsedSearch.token === 'string'
        ? getAuthTokenWith2FA({
            twoFactorTokenId: parsedSearch.token,
          }).then((response) => {
            logIn(response);
            return response;
          })
        : Promise.reject(new Error(errorMessageKeys.missing2FAToken)),
    enabled: !!parsedSearch.token,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  return {
    getAuthTokenWith2FAQuery,
  };
};
