import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { QUERY_KEYS } from 'shared/constants/queryKeys';
import {
  fetchAllUsers,
  deleteUser,
  markUserDnaAsContaminated,
  updateUser,
  type UserItem,
  type DeleteUserRequirements,
  getUsersCsvBulk,
  getUsersCsvSelection,
  type GetUsersCsvSelectionRequirements,
  sendWebhookEventSelection,
  type SendNotificationSelectionRequirements,
  type SendNotificationBulkRequirements,
  sendWebhookEventBulk,
} from './allUsersAPI';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'shared/utils/downloadFile';
import { renderCopyapbleNotification } from 'shared/utils/renderCopyableNotification';

export const useAllUsersQuery = () => {
  const { search } = useLocation();

  const allUsersQuery = useQuery({
    queryKey: QUERY_KEYS.ALL_USERS.list(search),
    queryFn: () => fetchAllUsers(search),
  });

  return {
    allUsersQuery,
  };
};

export const useAllUsersMutations = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const deleteUserMutation = useMutation({
    mutationFn: ({ companyId, userId }: DeleteUserRequirements) =>
      deleteUser({ companyId, userId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ALL_USERS.lists() });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.COMPANY_USERS.lists(),
      });

      message.success(t('UpdateUser.requestSuccess'));
    },
    onError: () => message.error(t('UpdateUser.requestError')),
  });

  const updateUserMutation = useMutation({
    mutationFn: ({
      companyId,
      userId,
      field,
    }: {
      companyId: string;
      userId: string;
      field: Partial<UserItem>;
    }) => updateUser({ companyId, field, userId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ALL_USERS.lists() });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.COMPANY_USERS.lists(),
      });

      message.success(t('UpdateUser.requestSuccess'));
    },
    onError: () => message.error(t('UpdateUser.requestError')),
  });

  const markUserDnaAsContaminatedMutation = useMutation({
    mutationFn: ({
      companyId,
      userId,
      barcode,
    }: {
      companyId: string;
      userId: string;
      barcode?: string;
    }) => markUserDnaAsContaminated({ companyId, userId, barcode }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.ALL_USERS.lists(),
      });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.COMPANY_USERS.lists(),
      });
    },
  });

  const getUsersCsvBulkMutation = useMutation({
    mutationFn: (queryString: string) => getUsersCsvBulk(queryString),
    onSuccess: ({ data }) => {
      downloadFile(data, 'users-report');
    },
    onError: () => message.error(t('UpdateUser.requestError')),
  });

  const getUsersCsvSelectionMutation = useMutation({
    mutationFn: ({ referenceNumbers }: GetUsersCsvSelectionRequirements) =>
      getUsersCsvSelection({
        referenceNumbers,
      }),
    onSuccess: ({ data }) => {
      downloadFile(data, 'users-report');
    },
    onError: () => message.error(t('UpdateUser.requestError')),
  });

  const sendWebhookEventSelectionMutation = useMutation({
    mutationFn: (values: SendNotificationSelectionRequirements) =>
      sendWebhookEventSelection(values),
    onSuccess: ({ data: { failed, notified, unknowns } }, variables) => {
      if (notified.length) {
        message.success(
          t('UpdateUser.notificationSuccess', {
            eventType: variables.event,
          }),
        );
      }

      if (failed.length) {
        renderCopyapbleNotification({
          t,
          textToCopy: failed.join(', '),
          warningMessage: t('UpdateUser.notificationFailed'),
        });
      }

      if (unknowns.length) {
        renderCopyapbleNotification({
          t,
          textToCopy: unknowns.join(', '),
          warningMessage: t('UpdateUser.notificationUnknown'),
        });
      }
    },
    onError: (_data, variables) =>
      message.error(
        t('UpdateUser.notificationError', {
          eventType: variables.event,
        }),
      ),
  });

  const sendWebhookEventBulkMutation = useMutation({
    mutationFn: (values: SendNotificationBulkRequirements) =>
      sendWebhookEventBulk(values),
    onSuccess: ({ data: { failed, notified } }, variables) => {
      if (notified.length) {
        message.success(
          t('UpdateUser.notificationSuccess', {
            eventType: variables.event,
          }),
        );
      }
      if (failed.length) {
        renderCopyapbleNotification({
          t,
          textToCopy: failed.join(', '),
          warningMessage: t('UpdateUser.notificationFailed'),
        });
      }
    },
    onError: (_data, variables) =>
      message.error(
        t('UpdateUser.notificationError', {
          eventType: variables.event,
        }),
      ),
  });

  return {
    deleteUserMutation,
    updateUserMutation,
    markUserDnaAsContaminatedMutation,
    getUsersCsvBulkMutation,
    getUsersCsvSelectionMutation,
    sendWebhookEventSelectionMutation,
    sendWebhookEventBulkMutation,
  };
};
