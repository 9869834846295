import { type SchoolName } from 'features/pages/UserDetails/Results/results';
import { type AgeGroup, type ResultType } from 'shared/types';

export const isAgeGroup = (ageGroup: any): ageGroup is AgeGroup =>
  ['1', '2', '3', '4'].includes(ageGroup);

export const isResult = (result: any): result is ResultType =>
  ['basic', 'complete'].includes(result);

export const isSchoolResult = (results: any[]): results is SchoolName[] =>
  results[0]?.label !== undefined;

export const isSchoolName = (schoolName: any): schoolName is SchoolName =>
  schoolName.label !== undefined;
