import { type ReactNode } from 'react';
import { Col } from 'antd';
import { type Question } from 'features/pages/Quiz/api/quizApi';
import { QuestionWrapper } from '../../components/Questions/QuestionWrapper/QuestionWrapper';
import { TextHighlighter } from '../../components/TextHighlighter/TextHighlighter';
import { QuestionImage } from '../../components/Images/QuestionImage/QuestionImage';

interface Props {
  question: Question;
  children: ReactNode;
  isSubmitDisabled: boolean;
}

export const PerceptionQuestion = ({
  children,
  question,
  isSubmitDisabled,
}: Props) => {
  return (
    <QuestionWrapper
      isSubmitDisabled={isSubmitDisabled}
      renderQuestion={() => (
        <>
          <Col span={24}>
            <TextHighlighter>{question.question.text}</TextHighlighter>
          </Col>
          <Col span={14}>
            <QuestionImage
              src={question.question.imageUrl}
              alt={question.question.text}
            />
          </Col>
        </>
      )}
    >
      {children}
    </QuestionWrapper>
  );
};
