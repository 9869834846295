const allUsersQuery = {
  all: ['allUsers'] as const,
  lists: () => [...allUsersQuery.all, 'list'] as const,
  list: (filters: string) => [...allUsersQuery.lists(), { filters }] as const,
  details: () => [...allUsersQuery.all, 'detail'] as const,
  detail: (id: string) => [...allUsersQuery.details(), id] as const,
};

const companiesQuery = {
  all: ['companies'] as const,
  lists: () => [...companiesQuery.all, 'list'] as const,
  list: (filters: string) => [...companiesQuery.lists(), { filters }] as const,
  details: () => [...companiesQuery.all, 'detail'] as const,
  detail: (id: string) => [...companiesQuery.details(), id] as const,
};

const companyUsersQuery = {
  all: ['companyUsers'] as const,
  lists: () => [...companyUsersQuery.all, 'list'] as const,
  list: (filters: string) =>
    [...companyUsersQuery.lists(), { filters }] as const,
  details: () => [...companyUsersQuery.all, 'detail'] as const,
  detail: (id: string) => [...companyUsersQuery.details(), id] as const,
};

const quizQuery = {
  all: ['quiz'] as const,
};

const userQuizTokenQuery = {
  all: ['userQuizToken'] as const,
  details: () => [...userQuizTokenQuery.all, 'detail'] as const,
  detail: (id: string) => [...userQuizTokenQuery.details(), id] as const,
};

const use2FATokenQuery = {
  all: ['2FAToken'] as const,
};

const userDetails = {
  all: ['userDetails'] as const,
  details: (companyId?: string, referenceNumber?: string) =>
    [...userDetails.all, 'details', companyId, referenceNumber] as const,
};

export const QUERY_KEYS = {
  ALL_USERS: allUsersQuery,
  COMPANIES: companiesQuery,
  COMPANY_USERS: companyUsersQuery,
  QUIZ: quizQuery,
  USER_QUIZ_TOKEN: userQuizTokenQuery,
  USE_2FA_TOKEN: use2FATokenQuery,
  USER_DETAILS: userDetails,
};
