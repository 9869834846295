import { CopyOutlined } from '@ant-design/icons';
import { Typography, message } from 'antd';
import { type TFunction } from 'react-i18next';

export const renderCopyapbleNotification = ({
  textToCopy,
  warningMessage,
  durationSeconds = 8,
  t,
}: {
  textToCopy: string;
  warningMessage: string;
  durationSeconds?: number;
  t: TFunction;
}) => {
  return message.warning({
    duration: durationSeconds,
    content: (
      <>
        <Typography.Text>{warningMessage}</Typography.Text>
        <Typography.Text
          strong
          copyable={{
            text: textToCopy,
            icon: (
              <>
                <Typography.Link>
                  {t('UpdateUser.notificationWarningCopy')}{' '}
                </Typography.Link>
                <CopyOutlined />
              </>
            ),
          }}
        />
      </>
    ),
  });
};
