import { Badge } from 'antd';
import { type DnaTestStatus as DnaTestStatusType } from 'features/pages/AllUsers/api/allUsersAPI';
import { useTranslation } from 'react-i18next';

interface Props {
  dnaTestStatus: DnaTestStatusType;
}

const DnaTestStatus = ({ dnaTestStatus }: Props) => {
  const { t } = useTranslation();

  switch (dnaTestStatus) {
    case 'DnaTestOrdered': {
      return (
        <Badge color="blue" text={t('UserTable.TestStatus.dnaTestOrdered')} />
      );
    }
    case 'DnaTestReceived': {
      return (
        <Badge
          color="purple"
          text={t('UserTable.TestStatus.dnaTestReceived')}
        />
      );
    }
    case 'DnaTestStarted': {
      return (
        <Badge color="orange" text={t('UserTable.TestStatus.dnaTestStarted')} />
      );
    }
    case 'DnaTestContaminated': {
      return (
        <Badge
          color="brown"
          text={t('UserTable.TestStatus.dnaTestContaminated')}
        />
      );
    }
    case 'DnaTestCompleted': {
      return (
        <Badge
          color="green"
          text={t('UserTable.TestStatus.dnaTestCompleted')}
        />
      );
    }
    default:
      return <Badge color="red" text={t('UserTable.TestStatus.noDNA')} />;
  }
};

export default DnaTestStatus;
