import { Button, Form, Row, theme } from 'antd';
import { useEffect, useState } from 'react';
import styles from '../Answers.module.scss';
import { type AnswerType } from 'features/pages/Quiz/api/quizApi';
import { questionFormInitialValues } from '../../QuizQuestions';
import { AnswersWrapper } from '../../components/Answers/AnswersWrapper/AnswersWrapper';
import { TextHighlighter } from '../../components/TextHighlighter/TextHighlighter';
import { ImageAnswerWrapper } from '../../components/Answers/ImageAnswerWrapper/ImageAnswerWrapper';
import { CoveredImage } from '../../components/Images/CoveredImage/CoveredImage';
import { COUNTER_QUESTION } from 'features/pages/Quiz/shared/constants/localStorageKeys';
import {
  type QuestionForm,
  type QuestionFormKeys,
} from 'features/pages/Quiz/shared/types/types';

interface Props {
  answers: AnswerType[];
  questionFormKey: QuestionFormKeys;
}

export const CounterQuestionAnswers = ({ answers, questionFormKey }: Props) => {
  const form = Form.useFormInstance();
  const watchedAnswers =
    Form.useWatch<QuestionForm['answers']>(questionFormKey) ||
    questionFormInitialValues.answers;
  const { token } = theme.useToken();
  const [flipped, setFlipped] = useState<string | null>(null);
  const [seenImages, setSeenImages] = useState<string[]>([]);

  useEffect(() => {
    sessionStorage.setItem(COUNTER_QUESTION, JSON.stringify(true));
  }, []);

  return (
    <AnswersWrapper
      renderHelperText={() => (
        <TextHighlighter>Tick all you remember.</TextHighlighter>
      )}
    >
      <Row gutter={[16, 0]} justify={'center'}>
        {answers.map((answer) => {
          const isAnswerFlipped = flipped === answer.id;
          const selectedStateColor = watchedAnswers.includes(answer.id)
            ? token.colorPrimary
            : token.colorBorder;
          return (
            <ImageAnswerWrapper
              key={answer.id}
              answerId={answer.id}
              color={selectedStateColor}
              themeConfig={{
                token: {
                  colorFill: '#91EE91',
                },
              }}
            >
              <CoveredImage
                flipped={isAnswerFlipped}
                imageUrl={answer.imageUrl}
                imageText={answer.text}
                isCounterQuestion={true}
                seenImage={!!seenImages.find((image) => image === answer.id)}
                render={(data) => {
                  return (
                    <Form.Item
                      className={styles.formItem}
                      name={questionFormKey}
                    >
                      <Button
                        onMouseEnter={() => {
                          setFlipped((prev) =>
                            prev === answer.id ? null : answer.id,
                          );
                        }}
                        onMouseLeave={() => {
                          setFlipped(null);
                          setSeenImages((prev) => [...prev, answer.id]);
                        }}
                        onClick={() => {
                          if (
                            Array.isArray(watchedAnswers) &&
                            isAnswerFlipped
                          ) {
                            form.setFieldValue(
                              questionFormKey,
                              watchedAnswers.includes(answer.id)
                                ? watchedAnswers.filter(
                                    (answerId: string) =>
                                      answerId !== answer.id,
                                  )
                                : [...watchedAnswers, answer.id],
                            );
                          }
                        }}
                        className={styles.answerButton}
                      >
                        {data}
                      </Button>
                    </Form.Item>
                  );
                }}
              />
            </ImageAnswerWrapper>
          );
        })}
      </Row>
    </AnswersWrapper>
  );
};
