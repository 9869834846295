import { Button, Form, Row, theme } from 'antd';

import styles from '../Answers.module.scss';
import { type AnswerType } from 'features/pages/Quiz/api/quizApi';
import {
  type QuestionForm,
  type QuestionFormKeys,
} from 'features/pages/Quiz/shared/types/types';
import { questionFormInitialValues } from '../../QuizQuestions';
import { ImageAnswerWrapper } from '../../components/Answers/ImageAnswerWrapper/ImageAnswerWrapper';
import { AnswerImage } from '../../components/Images/AnswerImage/AnswerImage';

interface Props {
  answers: AnswerType[];
  type: 'single' | 'multiple';
  questionFormKey: QuestionFormKeys;
}

export const ImageAnswers = ({ answers, type, questionFormKey }: Props) => {
  const form = Form.useFormInstance();
  const watchedAnswers =
    Form.useWatch<QuestionForm['answers']>(questionFormKey) ||
    questionFormInitialValues.answers;
  const { token } = theme.useToken();

  return (
    <Row gutter={[16, 16]} justify={'center'}>
      {answers.map((answer) => {
        const selectedStateColor = watchedAnswers.includes(answer.id)
          ? token.colorPrimary
          : token.colorBorder;
        return (
          // TODO maybe not commit this
          <ImageAnswerWrapper
            key={answer.id}
            answerId={answer.id}
            color={selectedStateColor}
          >
            <AnswerImage
              src={answer.imageUrl}
              answerText={answer.text}
              render={(data) => {
                return (
                  <Form.Item className={styles.formItem} name={questionFormKey}>
                    <Button
                      className={styles.answerButton}
                      onClick={() => {
                        if (type === 'single') {
                          form.setFieldValue(questionFormKey, answer.id);
                        }
                        if (
                          type === 'multiple' &&
                          Array.isArray(watchedAnswers)
                        ) {
                          form.setFieldValue(
                            questionFormKey,
                            watchedAnswers.includes(answer.id)
                              ? watchedAnswers.filter(
                                  (answerId: string) => answerId !== answer.id,
                                )
                              : [...watchedAnswers, answer.id],
                          );
                        }
                      }}
                    >
                      {data}
                    </Button>
                  </Form.Item>
                );
              }}
            />
          </ImageAnswerWrapper>
        );
      })}
    </Row>
  );
};
