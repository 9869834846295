import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from 'react';
import { USER_QUIZ_TOKEN } from 'shared/constants/localStorageKeys';

export interface UserQuizData {
  companyId: string;
  userId: string;
  userQuizToken: string;
}

interface QuizContextValues {
  parentPart: boolean;
  setParentPart: Dispatch<SetStateAction<boolean>>;
  userData: UserQuizData;
  updateUserData: (userData: QuizContextValues['userData']) => void;
  setUserData: (userData: QuizContextValues['userData']) => void;
}

const quizContextInitialData = {
  parentPart: false,
  setParentPart: () => undefined,
  userData: {
    companyId: '',
    userId: '',
    userQuizToken: '',
  },
  updateUserData: () => undefined,
  setUserData: () => undefined,
};

const QuizContext = createContext<QuizContextValues>(quizContextInitialData);

export const QuizContextProvider = ({ children }: { children: ReactNode }) => {
  const [parentPart, setParentPart] = useState<QuizContextValues['parentPart']>(
    quizContextInitialData.parentPart,
  );
  const [userData, setUserData] = useState<QuizContextValues['userData']>(
    quizContextInitialData.userData,
  );

  const updateUserData = (newUserData: QuizContextValues['userData']) => {
    setUserData((currentData) => ({ ...currentData, ...newUserData }));
  };

  useEffect(() => {
    if (userData.userQuizToken) {
      localStorage.setItem(
        USER_QUIZ_TOKEN,
        JSON.stringify(userData.userQuizToken),
      );
    }
  }, [userData.userQuizToken]);

  const memoizedValues = useMemo(() => {
    return {
      parentPart,
      userData,
      setParentPart,
      updateUserData,
      setUserData,
    };
  }, [parentPart, userData]);

  return (
    <QuizContext.Provider value={memoizedValues}>
      {children}
    </QuizContext.Provider>
  );
};

export const useQuizContext = () => useContext(QuizContext);
