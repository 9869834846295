import { Breadcrumb, message, Typography } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { paths } from 'helpers/routing/paths';
import CompanyForm, {
  type CompanyData,
} from 'features/common/forms/CompanyForm/CompanyForm';
import styles from './EditCompany.module.scss';
import { saveEditedCompany } from './editCompanyAPI';
import { useCompanyDetailsQuery } from '../CompanyDetails/api/useCompanyQuery';
import { PurchasedProducts } from '../CompanyDetails/api/companyAPI';

const EditCompany = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId: string }>();
  const { companyDetailsQuery } = useCompanyDetailsQuery();
  const companyDetailsData = companyDetailsQuery.data?.data.company;
  const isLoading =
    companyDetailsQuery.isLoading || companyDetailsQuery.isFetching;

  const onFinish = (values: CompanyData) => {
    if (!companyId) return;

    saveEditedCompany(values, companyId)
      .then(() => {
        message.success(t('EditCompany.requestSuccess'));
        navigate(`${paths.companies}/${companyId}`);
      })
      .catch(() => message.error(t('EditCompany.requestError')));
  };

  if (isLoading || !companyDetailsData) return null;

  const initialValues: CompanyData = {
    address: {
      city: companyDetailsData.address.city,
      country: companyDetailsData.address.country,
      state: companyDetailsData.address.state,
      zipCode: companyDetailsData.address.zipCode,
      street: companyDetailsData.address.street,
      streetNumber: companyDetailsData.address.streetNumber,
    },
    email: companyDetailsData.email,
    generateCompanyId: '???',
    hasDnaTest: companyDetailsData.purchasedProducts.includes(
      PurchasedProducts.dnaTest,
    ),
    hasPsychologicalAssessment: companyDetailsData.purchasedProducts.includes(
      PurchasedProducts.psychologicalAssessment,
    ),
    hasTestForProfessions: companyDetailsData.purchasedProducts.includes(
      PurchasedProducts.testForProfessions,
    ),
    hasManagementStyle: companyDetailsData.purchasedProducts.includes(
      PurchasedProducts.managementStyle,
    ),
    name: companyDetailsData.name,
    phone: companyDetailsData.phone,
  };

  return (
    <div className={styles.container}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/">{t('Navbar.Home')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/companies">{t('Navbar.Companies')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/">{t('EditCompany.route')}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Typography.Title level={4}>{t('EditCompany.title')}</Typography.Title>
      <Typography.Text>{t('EditCompany.description')}</Typography.Text>
      <CompanyForm
        key={companyDetailsData.id}
        onFinish={onFinish}
        initialValues={initialValues}
      />
    </div>
  );
};

export default EditCompany;
