import { Col, Row, Select, type SelectProps } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useApplySearchParams } from 'shared/hooks/useSearchParamsState';
import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
} from 'shared/constants/defaultPagination';
import { PAGING_KEYS } from 'shared/constants/apiResponseKeys';

export const FilterSelect = ({
  name,
  label,
  ...selectProps
}: SelectProps & { name: string; label: string }) => {
  const [searchParams] = useSearchParams();
  const acquiredSearchParam = searchParams.get(name);
  const limit = searchParams.get(PAGING_KEYS.limit);
  const { applySearchParamsValues } = useApplySearchParams({
    paging: {
      limit: {
        value: limit ? +limit : DEFAULT_LIMIT,
      },
      offset: {
        value: DEFAULT_OFFSET,
      },
    },
  });

  const searchParamState = acquiredSearchParam ?? undefined;
  const [fieldValue, setFieldValue] = useState<string | undefined>(
    searchParamState,
  );

  useEffect(() => {
    if (!searchParamState && fieldValue) {
      setFieldValue(searchParamState);
    }
  }, [searchParamState]);

  const handleSearch = (value: string) => {
    setFieldValue(value);
    applySearchParamsValues({
      filtering: {
        [name]: {
          value,
        },
      },
    });
  };

  return (
    <Row gutter={[0, 12]}>
      <Col span={24}>
        <label htmlFor={name}>{label}</label>
      </Col>
      <Col span={24}>
        <Select
          id={name}
          style={{ width: '100%' }}
          value={fieldValue}
          onChange={handleSearch}
          allowClear
          {...selectProps}
        />
      </Col>
    </Row>
  );
};
