import { Dropdown, Modal } from 'antd';
import { MoreOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import getUserDetailsPath from 'features/common/tables/UserTable/helpers/getUserDetailsPath';
import { type MenuItem } from 'shared/types';
import {
  type UserItem,
  WebhookEventType,
} from 'features/pages/AllUsers/api/allUsersAPI';
import { useAllUsersMutations } from 'features/pages/AllUsers/api/useAllUsersQuery';
import { useModalContext } from 'features/pages/Quiz/shared/context/ModalContext';
import { PsyTestStatuses } from 'features/pages/AllUsers/api/allUsersAPI';

interface Props {
  record: UserItem;
}

const ActionDropdown = ({ record }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    deleteUserMutation,
    markUserDnaAsContaminatedMutation,
    sendWebhookEventSelectionMutation,
  } = useAllUsersMutations();
  const { openModal } = useModalContext();

  const confirmMarkAsContaminated = () => {
    Modal.confirm({
      title: t('UserTable.Actions.confirmMarkAsContaminated'),
      icon: <ExclamationCircleOutlined />,
      okText: t('UserTable.Actions.submit'),
      cancelText: t('UserTable.Actions.cancel'),
      onOk: () => {
        markUserDnaAsContaminatedMutation.mutate({
          companyId: record.companyId,
          userId: record.referenceNumber,
          barcode: record.barcode ?? undefined,
        });
      },

      width: 600,
    });
  };

  const confirmDnaReset = () => {
    Modal.confirm({
      title: t('UserTable.Actions.confirmDnaReset'),
      icon: <ExclamationCircleOutlined />,
      okText: t('UserTable.Actions.submit'),
      cancelText: t('UserTable.Actions.cancel'),
      // eslint-disable-next-line no-console
      onOk: () => {
        console.log('confirmDnaReset');
      },
      width: 700,
    });
  };

  const confirmPsyReset = () => {
    Modal.confirm({
      title: t('UserTable.Actions.confirmPsyReset'),
      icon: <ExclamationCircleOutlined />,
      okText: t('UserTable.Actions.submit'),
      cancelText: t('UserTable.Actions.cancel'),
      // eslint-disable-next-line no-console
      onOk: () => {
        console.log('confirmPsyReset');
      },
      width: 700,
    });
  };

  const confirmDeleteUser = () => {
    Modal.confirm({
      title: t('UserTable.Actions.confirmDeleteUser'),
      icon: <ExclamationCircleOutlined />,
      okText: t('UserTable.Actions.submit'),
      cancelText: t('UserTable.Actions.cancel'),
      onOk: () => {
        deleteUserMutation.mutate({
          companyId: record.companyId,
          userId: record.referenceNumber,
        });
      },
    });
  };

  const items: MenuItem[] = [
    {
      key: '1',
      onClick: () => {
        navigate(getUserDetailsPath(record));
      },
      label: t('UserTable.Actions.userDashboard'),
    },
    {
      key: 'edit',
      onClick: () => {
        navigate(getUserDetailsPath(record), {
          state: { edit: true },
        });
      },
      label: t('UserTable.Actions.edit'),
    },
    {
      key: 'comments',
      onClick: () => {
        openModal('comment', {
          comments: record.comments,
          companyId: record.companyId,
          userId: record.referenceNumber,
        });
      },
      label: record.comments
        ? t('UserTable.Actions.editComment')
        : t('UserTable.Actions.addComment'),
    },
    {
      key: 'barcode',
      onClick: () => {
        openModal('barcode', {
          barcode: record.barcode,
          userId: record.referenceNumber,
          companyId: record.companyId,
        });
      },
      label: record.barcode
        ? t('UserTable.Actions.editBarcode')
        : t('UserTable.Actions.addBarcode'),
    },
    ...(record.dnaTestStatus === 'DnaTestOrdered'
      ? ([
          {
            key: 'markAsContaminated',
            onClick: confirmMarkAsContaminated,
            label: t('UserTable.Actions.markAsContaminated'),
          },
        ] as MenuItem[])
      : []),
    {
      key: 'psyReset',
      onClick: confirmPsyReset,
      label: t('UserTable.Actions.psyReset'),
    },
    {
      key: 'dnaReset',
      onClick: confirmDnaReset,
      label: t('UserTable.Actions.dnaReset'),
    },
    {
      key: 'deleteUser',
      onClick: confirmDeleteUser,
      label: t('UserTable.Actions.deleteUser'),
    },
    {
      key: 'triggerNotification',
      label: t('UserTable.Actions.triggerNotification'),
      children: [
        {
          key: 'PsychologicalTestCompleted',
          onClick: () => {
            sendWebhookEventSelectionMutation.mutate({
              event: WebhookEventType.PsychologicalTestCompleted,
              referenceNumbers: [record.referenceNumber],
            });
          },
          label: t('UserTable.Actions.Webhook.PsychologicalTestCompleted'),
        },
        {
          key: 'DNACompleted',
          onClick: () => {
            sendWebhookEventSelectionMutation.mutate({
              event: WebhookEventType.DNACompleted,
              referenceNumbers: [record.referenceNumber],
            });
          },
          label: t('UserTable.Actions.Webhook.DNACompleted'),
        },
        {
          key: 'DNAContaminated',
          onClick: () => {
            sendWebhookEventSelectionMutation.mutate({
              event: WebhookEventType.DNAContaminated,
              referenceNumbers: [record.referenceNumber],
            });
          },
          label: t('UserTable.Actions.Webhook.DNAContaminated'),
        },
        {
          key: 'DNAReceived',
          onClick: () => {
            sendWebhookEventSelectionMutation.mutate({
              event: WebhookEventType.DNAReceived,
              referenceNumbers: [record.referenceNumber],
            });
          },
          label: t('UserTable.Actions.Webhook.DNAReceived'),
        },
        {
          key: 'DNAStarted',
          onClick: () => {
            sendWebhookEventSelectionMutation.mutate({
              event: WebhookEventType.DNAStarted,
              referenceNumbers: [record.referenceNumber],
            });
          },
          label: t('UserTable.Actions.Webhook.DNAStarted'),
        },
      ],
    },
    {
      key: 'generateQuizLink',
      onClick: () => {
        openModal('quiz', {
          companyId: record.companyId,
          userId: record.referenceNumber,
        });
      },
      disabled: record.psyTestStatus === PsyTestStatuses.completed,
      label: t('UserTable.Actions.generateQuizLink'),
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <MoreOutlined />
    </Dropdown>
  );
};

export default ActionDropdown;
