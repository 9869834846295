import { Button, Checkbox, Divider, Form, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './CompanyForm.module.scss';

export interface CompanyData {
  address: {
    city: string;
    country: string;
    state: string;
    zipCode: string;
    street: string;
    streetNumber: string;
  };
  email: string;
  generateCompanyId: string;
  hasDnaTest: boolean;
  hasPsychologicalAssessment: boolean;
  hasTestForProfessions: boolean;
  hasManagementStyle: boolean;
  name: string;
  phone: string;
}

interface ICompanyFormProps {
  onFinish: (values: CompanyData) => void;
  initialValues?: CompanyData;
}

const CompanyForm = ({ onFinish, initialValues }: ICompanyFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const requiredRule = {
    required: true,
    message: t('CompanyDetailsForm.fieldIsRequired'),
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="CompanyDetailsForm"
      className={styles.container}
      onFinish={onFinish}
      initialValues={initialValues}
      validateTrigger="onChange"
    >
      <div className={styles.form}>
        <Typography.Title level={5}>
          {t('CompanyDetailsForm.mandatoryData')}
        </Typography.Title>
        <Divider className={styles.divider} />
        <div className={styles.fields}>
          <Form.Item
            label={t('CompanyDetailsForm.name')}
            className={styles.item}
            name="name"
            rules={[requiredRule]}
          >
            <Input type="string" placeholder={t('CompanyDetailsForm.name')} />
          </Form.Item>
          <Form.Item
            label={t('CompanyDetailsForm.email')}
            className={styles.item}
            name="email"
            rules={[
              requiredRule,
              {
                type: 'email',
                message: t('CompanyDetailsForm.invalidEmail'),
              },
            ]}
          >
            <Input type="email" placeholder={t('CompanyDetailsForm.email')} />
          </Form.Item>
          <Form.Item
            label={t('CompanyDetailsForm.phoneNumber')}
            className={styles.item}
            name="phone"
            rules={[requiredRule]}
          >
            <Input
              type="string"
              placeholder={t('CompanyDetailsForm.phoneNumber')}
            />
          </Form.Item>
          <Form.Item
            label={t('CompanyDetailsForm.country')}
            className={styles.item}
            name={['address', 'country']}
            rules={[requiredRule]}
          >
            <Input
              type="string"
              placeholder={t('CompanyDetailsForm.country')}
            />
          </Form.Item>
          <Form.Item
            label={t('CompanyDetailsForm.stateOrRegion')}
            className={styles.item}
            name={['address', 'state']}
            rules={[requiredRule]}
          >
            <Input
              type="string"
              placeholder={t('CompanyDetailsForm.stateOrRegion')}
            />
          </Form.Item>
          <Form.Item
            label={t('CompanyDetailsForm.zipCode')}
            className={styles.item}
            name={['address', 'zipCode']}
            rules={[requiredRule]}
          >
            <Input
              type="string"
              placeholder={t('CompanyDetailsForm.zipCode')}
            />
          </Form.Item>
          <Form.Item
            label={t('CompanyDetailsForm.city')}
            className={styles.item}
            name={['address', 'city']}
            rules={[requiredRule]}
          >
            <Input type="string" placeholder={t('CompanyDetailsForm.city')} />
          </Form.Item>
          <Form.Item
            label={t('CompanyDetailsForm.street')}
            className={styles.item}
            name={['address', 'street']}
            rules={[requiredRule]}
          >
            <Input type="string" placeholder={t('CompanyDetailsForm.street')} />
          </Form.Item>
          <Form.Item
            label={t('CompanyDetailsForm.streetNumber')}
            className={styles.item}
            name={['address', 'streetNumber']}
            rules={[requiredRule]}
          >
            <Input
              type="string"
              placeholder={t('CompanyDetailsForm.streetNumber')}
            />
          </Form.Item>
        </div>
      </div>
      <div className={styles.form}>
        <Typography.Title level={5}>
          {t('CompanyDetailsForm.typeOfTesting')}
        </Typography.Title>
        <Divider className={styles.divider} />
        <div className={styles.rowCheckBoxes}>
          <Form.Item
            valuePropName="checked"
            className={styles.item}
            name="hasDnaTest"
            rules={[
              ({ getFieldValue }) => ({
                transform: (value) => value || false,
                type: 'boolean',
                validator(_, value) {
                  if (!value && !getFieldValue('hasPsychologicalAssessment')) {
                    return Promise.reject(
                      new Error(
                        t('CompanyDetailsForm.isOneOfProductsRequired'),
                      ),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Checkbox>{t('CompanyDetailsForm.dnaTest')}</Checkbox>
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            className={styles.item}
            name="hasPsychologicalAssessment"
          >
            <Checkbox onChange={() => form.validateFields(['hasDnaTest'])}>
              {t('CompanyDetailsForm.psychologicalAssessment')}
            </Checkbox>
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            className={styles.item}
            name="hasTestForProfessions"
            rules={[
              ({ getFieldValue }) => ({
                transform: (value) => value || false,
                type: 'boolean',
                validator(_, value) {
                  if (
                    value &&
                    !(
                      getFieldValue('hasDnaTest') &&
                      getFieldValue('hasPsychologicalAssessment')
                    )
                  ) {
                    return Promise.reject(
                      new Error(t('CompanyDetailsForm.isTraitsTestRequired')),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Checkbox>{t('CompanyDetailsForm.dnaTestForProfessions')}</Checkbox>
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            className={styles.item}
            name="hasManagementStyle"
            rules={[
              ({ getFieldValue }) => ({
                transform: (value) => value || false,
                type: 'boolean',
                validator(_, value) {
                  if (
                    value &&
                    !(
                      getFieldValue('hasDnaTest') &&
                      getFieldValue('hasPsychologicalAssessment')
                    )
                  ) {
                    return Promise.reject(
                      new Error(t('CompanyDetailsForm.isTraitsTestRequired')),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Checkbox>
              {t('CompanyDetailsForm.dnaTestForManagementStyle')}
            </Checkbox>
          </Form.Item>
        </div>
      </div>
      <Form.Item className={styles.submitButton}>
        <Button block type="primary" htmlType="submit">
          {initialValues
            ? t('CompanyDetailsForm.saveChanges')
            : t('CompanyDetailsForm.addCompany')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CompanyForm;
