import { type TFunction } from 'react-i18next';
import { Badge, Button, Typography } from 'antd';
import { paths } from 'helpers/routing/paths';
import getCompanyDetailsPath from 'features/common/tables/UserTable/helpers/getCompanyDetailsPath';
import { type NavigateFunction } from 'react-router-dom';
import { type ColumnsType } from 'antd/es/table';
import ActionDropdown from './ActionDropdown/ActionDropdown';
import { type CompanyStatus, type CompanyItem } from '../api/companiesAPI';

export const getColumns = (
  t: TFunction,
  navigate: NavigateFunction,
): ColumnsType<CompanyItem> => [
  {
    title: t('Companies.companyName'),
    dataIndex: 'name',
    render: (name: string, record: CompanyItem) => (
      <Typography.Link
        ellipsis
        onClick={() => {
          navigate(`${paths.companies}/${record.id}`);
        }}
        copyable
      >
        {name}
      </Typography.Link>
    ),
    width: 160,
  },
  {
    title: t('Companies.companyId'),
    dataIndex: 'id',
    render: (name: string, record: CompanyItem) => (
      <Button
        size="small"
        onClick={() => {
          navigate(getCompanyDetailsPath(record.id));
        }}
        type="link"
      >
        <Typography.Link copyable>{name}</Typography.Link>
      </Button>
    ),
    width: 160,
  },
  {
    title: t('Companies.numberOfUsers'),
    dataIndex: 'userCounter',
    width: 140,
  },
  {
    title: t('Companies.status'),
    dataIndex: 'status',
    render: (status: CompanyStatus) => {
      switch (status) {
        case 'active':
          return <Badge color="green" text={t(`Companies.${status}`)} />;
        case 'inactive':
          return <Badge color="red" text={t(`Companies.${status}`)} />;
      }
    },
    width: 120,
  },
  {
    title: t('Companies.comments'),
    dataIndex: 'comments',
    render: (comment: string) => (
      <Typography.Paragraph
        ellipsis={{
          rows: 2,
          tooltip: comment,
        }}
      >
        {comment}
      </Typography.Paragraph>
    ),
    width: 250,
  },
  {
    title: t('Companies.added'),
    dataIndex: 'updatedAt',
    render: (updatedAt: string) => updatedAt.slice(0, 10),
    sorter: true,
    width: 100,
  },
  {
    title: t('Companies.actions'),
    dataIndex: 'actions',
    render: (_text: string, record: CompanyItem) => (
      <ActionDropdown key={record.id} record={record} />
    ),
    width: 90,
    fixed: 'right',
  },
];
