import {
  type PreparedResult,
  type PreparedResults,
  type PureResult,
  type SchoolName,
  type ScoreName,
  type ScoreResult,
} from 'features/pages/UserDetails/Results/results';
import { TestKeys, TestTypes } from 'features/pages/UserDetails/UserDetails';
import { merge } from 'lodash';

export const prepareTraitsResultsToForm = (
  results: PureResult[],
  key: TestKeys,
): PreparedResult[] => {
  if (!results) {
    return [];
  }

  const array: any = [];

  results.map((result) => {
    if (result?.[key]) {
      array.push(
        result[key]?.map((e: ScoreName) => ({
          name: e.name,
          [result.type.toLowerCase()]: e.score
            ?.toFixed(2)
            .replace(/(\.0+|0+)$/, ''),
        })),
      );
    }
    return undefined;
  });

  const calculated = array.length
    ? // @ts-expect-error FIX
      merge(...array).map((e, i: number) => ({ ...e, index: i + 1 }))
    : [];

  return calculated;
};

export const prepareResults = (
  result?: Array<ScoreName | SchoolName> | null,
): ScoreResult[] => {
  if (!result?.length) {
    return [];
  }

  return result.map((item) => ({
    name: item.name,
    score: Number(item.score?.toFixed(2)) || null,
  }));
};

export const prepareResultsToApi = (results: PreparedResults): PureResult[] => {
  if (!results) return [];

  const getTraits = (testType: TestTypes): ScoreName[] | null => {
    const propertyNames = Object.keys(results[TestKeys.traits]);

    if (!propertyNames.length) return null;

    return propertyNames.map(
      (name: string): ScoreName => ({
        name,
        // @ts-expect-error FIX
        score: results[TestKeys.traits][name][testType] ?? null,
      }),
    );
  };

  const psychologicalResults = {
    type: 'Psychological',
    traits: getTraits(TestTypes.psychological),
  };

  const dnaResults = {
    type: 'DNA',
    traits: getTraits(TestTypes.dna),
  };

  return [psychologicalResults, dnaResults];
};
