import { axiosInstance } from 'app/api';
import axios from 'axios';
import { type UserItem } from '../AllUsers/api/allUsersAPI';
import { type PureResult } from './Results/results';

interface Result {
  name: string;
  score: number;
}
export interface UpdateFinalResultsPayload {
  psyTraits?: Result[];
  dnaTraits?: Result[];
  professionsWithInterest?: Result[];
  professionsWithoutInterest?: Result[];
  schoolSubjectsWithInterest?: Result[];
  schoolSubjectsWithoutInterest?: Result[];
  managementStyles?: Result[];
}

export const fetchUser = async (companyId: string, referenceNumber: string) => {
  try {
    return await axiosInstance.get<UserItem>(
      `/companies/${companyId}/users/${referenceNumber}`,
    );
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

export const updateFinalResults = async (
  companyId: string,
  referenceNumber: string,
  payload: UpdateFinalResultsPayload,
) => {
  try {
    return await axiosInstance.post<PureResult[]>(
      `companies/${companyId}/users/${referenceNumber}/update-results`,
      payload,
    );
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new Error(`Request failed with status ${err.response.status}`);
      } else {
        throw new Error('Network error occurred');
      }
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};
