import { Col, Row, Typography } from 'antd';
import { CustomButton } from 'features/pages/Quiz/components/CustomButton/CustomButton';
import { type ReactNode } from 'react';
import styles from './QuestionWrapper.module.scss';

interface Props {
  counterQuestion?: {
    displayQuestion: boolean;
    timer: number;
  };
  isSubmitDisabled: boolean;
  renderQuestion: () => JSX.Element;
  children: ReactNode;
}

export const QuestionWrapper = ({
  renderQuestion,
  counterQuestion,
  isSubmitDisabled,
  children,
}: Props) => {
  return (
    <Row gutter={[16, 16]}>
      {(!counterQuestion || counterQuestion.displayQuestion) && (
        <Col span={24}>
          <Row gutter={[16, 16]} justify="center">
            {renderQuestion()}
          </Row>
        </Col>
      )}
      {(!counterQuestion ||
        (counterQuestion && !counterQuestion.displayQuestion)) && (
        <Col span={24}>{children}</Col>
      )}
      <Col span={24} className={styles.buttonContainer}>
        {!counterQuestion ||
        (counterQuestion && !counterQuestion.displayQuestion) ? (
          <CustomButton
            htmlType="submit"
            disabled={isSubmitDisabled}
            horozintalPadding={80}
          />
        ) : (
          <Typography.Title level={5}>
            {counterQuestion.timer}s
          </Typography.Title>
        )}
      </Col>
    </Row>
  );
};
