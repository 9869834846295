import { Result, Typography } from 'antd';
import { type FallbackProps } from 'react-error-boundary';
import styles from './ErrorPage.module.scss';
import { useErrorMessage } from 'shared/hooks/useErrorMessage';

export const ErrorPage = (
  props: Partial<FallbackProps> & { status?: number },
) => {
  const { error, status } = props;
  const { errorMessage } = useErrorMessage({
    error,
    status,
  });

  return (
    <div className={styles.wrapper}>
      <Result
        status={errorMessage.resultComponentStatusCode}
        title={errorMessage.title}
        subTitle={
          <>
            <Typography.Paragraph>{errorMessage.message}</Typography.Paragraph>
            {errorMessage.requestMessage && (
              <Typography.Paragraph
                type="secondary"
                className={styles.errorSecondaryText}
              >
                {errorMessage.requestMessage}
              </Typography.Paragraph>
            )}
          </>
        }
      />
    </div>
  );
};
