/* eslint-disable react/jsx-props-no-spreading */
import { Navigate, Outlet } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';
import { axiosInstance } from 'app/api';
import { useUserContext } from 'shared/context/UserContext';

export type IPrivateRouteProps = RouteProps & {
  redirectPath: string; // redirect path if don't authenticate route
};

const ProtectedAdminPanelRoute = ({ redirectPath }: IPrivateRouteProps) => {
  const { isLogged, logOut } = useUserContext();

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        logOut();
      }
      return Promise.reject(error);
    },
  );

  return isLogged ? (
    <Outlet />
  ) : (
    <Navigate to={{ pathname: redirectPath }} replace />
  );
};

export default ProtectedAdminPanelRoute;
